import { constants, getVh, getVw } from "@constants/constants";
import { Autocomplete, Icon, OutlinedInput, Popper, styled } from "@mui/material";
import { TextField } from "@mui/material";
import { PropsWithChildren } from "react";

const TextFieldCustom = styled(TextField)(({ theme }) => ({
  "&.MuiFormControl-root": {
    width: "100%",
    backgroundColor: "transparent",
    fontFamily: constants.fontFamily,
    backgroundImage: constants.backgroundImage,
    boxShadow: constants.backgroundShadow,
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    ".MuiOutlinedInput-root": {
      border: "none",
      padding: "0 10px",
      height: 63,
      fontFamily: constants.fontFamily,
      color: theme.palette.primary.main,
      fontSize: 21,
      [theme.breakpoints.up('desktop')]: {
        height: 45,
        fontSize: 14,
      },
    },
    ".MuiAutocomplete-endAdornment": {
      marginRight: "30px",
      "*.MuiSvgIcon-root": {
        color: theme.palette.primary.main,
      },
    },
    ".MuiOutlinedInput-notchedOutline": {
      border: "none",
    },
    ".Mui-focused": {
      border: "none",
      borderColor: theme.palette.primary.main,
      ".MuiOutlinedInput-root": {
        border: "none",
      },
    },
  },
}));

// <div role="presentation" className="MuiAutocomplete-popper css-e533c5-MuiAutocomplete-popper"
// eslint-disable-next-line max-len
//      style="position: absolute; inset: 0px auto auto 0px; width: 500px; margin: 0px; transform: translate3d(20px, 309px, 0px);"
//      data-popper-placement="bottom">
//   <div
// eslint-disable-next-line max-len
//     className="MuiPaper-root MuiPaper-elevation MuiPaper-rounded MuiPaper-elevation1 MuiAutocomplete-paper css-9e5uuu-MuiPaper-root-MuiAutocomplete-paper">
//
// </div>
const PopperCustom = styled(Popper)(({ theme }) => ({
  ".MuiPaper-root": {
    backgroundColor: "transparent",
  },
  "& .MuiAutocomplete-listbox": {
    border: `none`,
    borderRadius: "2px",
    borderTop: "none",
    marginTop: getVh(0),
    padding: "0 2px",
    backgroundColor: "transparent",
    backgroundImage: "-webkit-linear-gradient( 90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 100%)",
    boxShadow: "0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33)",
    "& > li": {
      color: theme.palette.primary.main,
      fontSize: 21,
      [theme.breakpoints.up('desktop')]: {
        fontSize: 14,
      },
      minHeight: "10px",
      padding: "10px",
      fontFamily: constants.fontFamily,
      textTransform: "capitalize",
      margin: 0,
    },
  },
}));

export interface TextFieldSearchProps extends PropsWithChildren<any> {
  items: any[];
  cb: any;
  onChange: any;
  onClick: any;
}

export const TextFieldSearch = (props: TextFieldSearchProps) => {
  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={props?.items ?? []}
      getOptionLabel={(option) => (option ? option.name : "")}
      style={{ width: "100%", height: getVh(61), position: "relative" }}
      PopperComponent={(props) => {
        return <PopperCustom {...props} />;
      }}
      onChange={(el, value) => props.cb(props?.items?.find(el=>el?.id === value.id))}
      renderInput={(params) => (
        <div style={{ display: "flex" }}>
          <TextFieldCustom 
            {...params}
            placeholder={"Enter search"} 
            onChange={props.onChange}
            inputProps={{
              ...params.inputProps,
              onKeyDown: (e) => {
                if (e.key === 'Enter') {
                  e.stopPropagation();
                }
              }
            }}
          />
          <Icon
            style={{
              position: "absolute",
              cursor: "pointer",
              alignSelf: "center",
              justifySelf: "center",
              right: 10,
              zIndex: 999,
              fontSize: 26,
              width: getVw(26),
              height: getVh(26),
            }}
            color={"primary"}
            onClick={props.onClick}
          >
            search
          </Icon>
        </div>
      )}
    />
  );
};
