import { AuthDto } from "@models/AuthDto";
import { Auth } from "aws-amplify";
import Amplify from "@utils/amplify";


export interface AspectRatioInfo{
  id: number;
  name: string;
}

export interface StateInfo{
  id: number;
  name: string;
}


export default class AppApi {
  public static async aspectRatioList(){
    const res = await Amplify.get('portalAPI', '/aspectratiolist', {})
    if(res?.error?.length){
      throw res.error.join(",")
    }
    return (res?.data || []) as AspectRatioInfo[];
  }

  public static async stateList(){
    const res = await Amplify.get('portalAPI', '/stateprovlist', {})
    if(res?.error?.length){
      throw res.error.join(",")
    }
    return (res?.data || []) as StateInfo[];
  }
}
