import { Header } from "@components/Header/Header";
import { Menu } from "@components/Menu/Menu";
import { TextFieldSearch } from "@components/UI/TextFieldSearch";
import { PropsWithChildren, ReactNode } from "react";

import styles from "./PageLayout.module.scss";

interface PageLayoutProps extends PropsWithChildren<any> {
  headerTitle?: string;
  footer?: ReactNode;
}
export const PageLayout = (props: PageLayoutProps) => {
  return (
    <div className={styles.page}>
      <Menu />
      <Header />
      <div className={styles.content}>{props?.children}</div>
      {props?.footer && <div className={styles.footer}>{props?.footer}</div>}
    </div>
  );
};
