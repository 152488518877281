import { constants, getVh } from "@constants/constants";
import { Button as Btn, styled } from "@mui/material";

export const Button = styled(Btn)(({ theme }) => ({
  color: theme.palette.primary.main,
  width: "100%",
  height: 70,
  fontSize: 18,
  fontWeight: "normal",
  lineHeight: 18,
  fontFamily: constants.fontFamily,
  backgroundColor: "transparent",
  border: "2px solid",
  borderRadius: 6,
  boxShadow: constants.backgroundShadow,
  borderColor: theme.palette.primary.main,
  [theme.breakpoints.up('desktop')]: {
    height: 50,
    fontSize: 14,
    lineHeight: 18,
  },
  // "-webkit-transform": "matrix( 1.31092266621129,0,0,1.30999581786143,0,0)",
  "&:hover": {
    color: "black",
    backgroundColor: theme.palette.primary.main,
  },
}));
