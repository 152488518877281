import UploadIcon from "@assets/icons/icon_upload.svg";
import Example1 from "@assets/images/example1.png";
import Example2 from "@assets/images/example2.png";
import Example3 from "@assets/images/example3.png";
import Example4 from "@assets/images/example4.png";
import Example5 from "@assets/images/example5.png";
import { ButtonIcon } from "@components/UI/ButtonIcon";

const imagesSrc = [Example1, Example2, Example3, Example4, Example5];

import { PageLayoutWithStepper } from "@components/PageLayoutWithStepper/PageLayoutWithStepper";
import { Button } from "@components/UI/Button";
import { ButtonFill } from "@components/UI/ButtonFill";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import React, { ChangeEvent, PropsWithChildren, useRef, useState } from "react";

import styles from "./uploadImage.module.scss";
import imageCompression from 'browser-image-compression';

const captions = [
  "Upload a close up photo of the installed screen",
  "Upload a photo that shows the installed screen from 10 feet away",
  "Upload a photo that shows the installed screen with a person in the picture",
  "Upload a photo that shows the environment of the installed screen with the screen in the picture",
  "Upload a photo that shows the environment of the installed screen with the screen not in the picture",
];

export const UploadImage = observer(() => {
  const {
    installerStore: { installer, setInstaller, stepInstall },
    screenStore: { currentScreen },
    siteStore: { currentSite },
    routerStore,
  } = useRootStore();
  const inputRef = useRef<any>();

  const onImageChange = async (event: any) => {
    if (event.target.files && event.target.files[0]) {
      const options = {
        maxSizeMB: 0.5,
        maxWidthOrHeight: 512,
        useWebWorker: true
      }
      const compressedFile = await imageCompression(event.target.files[0], options);
      const buffer = await compressedFile.arrayBuffer()

      const blob = new Blob([buffer])
      const reader = new FileReader();
      reader.onload = function(e){
        const base64 = e?.target?.result
        installer.images[stepInstall - 1] = {
          filename: event.target.files[0].name,
          content: base64
        };
        setInstaller(installer);
      };

      reader.readAsDataURL(blob);
    }
  };

  const continueInstall = () => {
    if (stepInstall >= 4) {
      routerStore.goTo("information", {
        params: { id: currentSite?.id, screenId: currentScreen?.serverid},
      });
    } else {
      routerStore.goTo("uploadImage", {
        params: { id: currentSite?.id, screenId: currentScreen?.serverid, step: stepInstall + 1 },
      });
    }
  };

  return (
    <PageLayoutWithStepper footer={<Button onClick={() => continueInstall()}>continue</Button>}>
      <div className={styles.content}>
        <h1>{currentScreen?.displayname}</h1>
        <span className={styles.title}>{captions?.[stepInstall - 1] ?? ""}</span>
        <div className={styles.images}>
          <div className={styles.current}>
            <h2>UPLOAD</h2>
            <div className={styles.imageBlock} onClick={() => inputRef?.current?.click()} role={"presentation"}>
              <input ref={inputRef} type="file" name="myImage" hidden onChange={onImageChange} />
              {installer.images?.[stepInstall - 1] ? (
                <img src={installer.images[stepInstall - 1].content} alt={""} />
              ) : (
                <ButtonIcon icon={UploadIcon} width={40} height={40} />
              )}
            </div>
          </div>
          <div className={styles.example}>
            <h2>EXAMPLE</h2>
            <img className={styles.exampleImage} src={imagesSrc[stepInstall - 1]} alt={""} />
          </div>
        </div>
      </div>
    </PageLayoutWithStepper>
  );
});
