import { Icon } from "@components/Icon/Icon";
import { constants, getVh, getVw } from "@constants/constants";
import { IconButton } from "@mui/material";
import { IconPropsColorOverrides } from "@mui/material/Icon/Icon";
import { OverridableStringUnion } from "@mui/types";
import * as React from "react";
import { PropsWithChildren, useEffect, useRef } from "react";

export interface ButtonIconProps extends PropsWithChildren<any> {
  icon: string;
  width?: number;
  height?: number;
  onClick?: any;
  title?: string;
  getRef?: (ref: React.MutableRefObject<any>)=>void;
}

export const ButtonIcon = (props: ButtonIconProps) => {
  const ref = useRef(null);
  const onClick = () => {
    props?.onClick?.(ref);
  }
  useEffect(()=>{
    if(ref.current && props.getRef){
      props?.getRef(ref)
    }
  },[ref])
  return (
    <IconButton ref={ref} onClick={onClick} style={{ display: "flex", cursor: "pointer", flexDirection: "column" }}>
      <Icon width={props.width ?? 32} height={props.height ?? 32} path={props.icon} />
      {props?.title && (
        <span
          style={{
            fontFamily: constants.fontFamily,
            color: constants.primary,
            fontSize: getVw(14),
            marginTop: getVh(3),
          }}
        >
          {props?.title}
        </span>
      )}
    </IconButton>
  );
};
