import { PageLayout } from "@components/PageLayout/PageLayout";
import { ButtonFill } from "@components/UI/ButtonFill";
import { TextFieldOutline } from "@components/UI/TextFieldOutline";
import { TextFieldSelect } from "@components/UI/TextFieldSelect";
import { classValidatorResolver } from "@hookform/resolvers/class-validator/dist/class-validator";
import { ContactDto } from "@models/ContactDto";
import SupportDto from "@models/SupportDto";
import { observer } from "mobx-react-lite";
import { browserHistory } from "mobx-state-router";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import styles from "./support.module.scss";

const resolver = classValidatorResolver(SupportDto);

export const Support = observer(() => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<SupportDto>({ resolver });

  const sendSupport = async (data: SupportDto) => {
    // const screen = data as ContactDto;
    // const res = await create(screen);
    // routerStore.goTo("screen", { params: { id: screen.siteId, screenId: res.id } });
    browserHistory.goBack();
  };

  return (
    <PageLayout
      headerTitle={"ASK FOR SUPPORT"}
      footer={<ButtonFill onClick={handleSubmit(sendSupport)}>create</ButtonFill>}
    >
      <div className={styles.content}>
        <h1>Customer Support Ticket</h1>
        <form className={styles.form}>
          <Controller
            name="siteName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.siteName?.message}
                label={"site name"}
                placeholder={"Enter a site name"}
              />
            )}
          />
          <Controller
            name="serverName"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.serverName?.message}
                label={"server name"}
                placeholder={"Enter a server name"}
              />
            )}
          />
          <Controller
            name="briefDescription"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                multiline
                height={"191px"}
                maxRows={20}
                rows={8}
                errorHelper={errors.briefDescription?.message}
                label={"brief description"}
                placeholder={"Enter a brief description for support"}
              />
            )}
          />
        </form>
        <div className={styles.delimiter}>- OR -</div>
        <h3>Call 978-845-5000</h3>
      </div>
    </PageLayout>
  );
});
