import { DisplayId } from "aws-sdk/clients/support";
import Amplify from "@utils/amplify";
import { SiteInfo } from "@services/siteApi";

export class ScreenInfo {
  serverid!: number;
  siteId!: number;
  displayname!: string;
  activationdate!: string;
  aspectratio!: number;
  installdate!: string;
  monitorindicator!: boolean;
  channelid!: number;
  iscloud!: boolean;
  lastlog!: string;
  locationinfo!: string;
  part!: string;
  photos!: { name: string; content: any; url?: string}[];
  activationId: string;
  uniqueid!: string;

  public constructor(init?: Partial<ScreenInfo>) {
    Object.assign(this, init);
  }
}

export class ScreenApi {
  public static async install(screen: ScreenInfo): Promise<ScreenInfo> {
    const versionResponse = await fetch('https://d3h71mf3yibkrg.cloudfront.net/player/version.json');
    const version = await versionResponse.json();
    const res = await Amplify.put('portalAPI', '/installdata', {
      serverid: screen.serverid,
      aspectratio: screen.aspectratio,
      activationid: screen.activationId,
      photos: screen.photos,
      library: `https://d3h71mf3yibkrg.cloudfront.net/player/${version.lib}`,
      locationinfo: screen.locationinfo,

    })
    if(res?.error?.length){
      throw res.error
    }
    return screen;
  }
}
