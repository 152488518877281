import { IsEmail, IsNotEmpty, IsNumber, IsString, Matches, MinLength, ValidateIf } from "class-validator";
import { Match } from "@utils/match.decorator";

export class ContactDto {
  id?: string;

  @IsString({ message: "Enter a name"})
  @IsNotEmpty({ message: "Enter a name"})
  name!: string;

  @IsEmail({ message: "Enter a valid email"})
  @IsNotEmpty({ message: "Enter a valid email"})
  email!: string;

  @IsString({message: "Enter a phone number"})
  @IsNotEmpty({message: "Enter a phone number"})
  phone!: string;

  @IsString()
  @MinLength(6, {message: `Must use at least 6 characters`})
  @Matches(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, {message: `Must use at least one special character`})
  password?: string;

  @IsString({message: 'Passwords do not match'})
  @IsNotEmpty({message: 'Passwords do not match'})
  @Match('password', {message: 'Passwords do not match'})
  confirmPassword?: string;

  @IsString({message: "Select a role"})
  @IsNotEmpty({message: "Select a role"})
  permission!: string;

  @ValidateIf(o => o.permission !== "Administrator")
  @IsNotEmpty()
  attribute!: string | number;

  locale?: string;
}
