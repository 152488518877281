import {PageLayout} from "@components/PageLayout/PageLayout";
import {Button} from "@components/UI/Button";
import {TextFieldOutline} from "@components/UI/TextFieldOutline";
import {TextFieldSelect} from "@components/UI/TextFieldSelect";
import {useRootStore} from "@context/rootStoreContext";
import {classValidatorResolver} from "@hookform/resolvers/class-validator";
import {ContactDto} from "@models/ContactDto";
import {observer} from "mobx-react-lite";
import {browserHistory} from "mobx-state-router";
import * as React from "react";
import {Controller, useForm} from "react-hook-form";
import InputMask from 'react-input-mask';
import styles from "./contact.module.scss";

const resolver = classValidatorResolver(ContactDto);

import {API, Auth} from "aws-amplify";
import { useEffect, useState } from "react";
import { BuildingClass } from "@services/contactApi";


export const ContactCreate = observer(() => {
  const [state,setState] = useState({ listCoordinators: [], buildingClassList: [] as BuildingClass[], groupsList: []})
  const [group, setGroup] = useState("")
  const {
    contactStore: {create, listCoordinators, buildingClassList, groupsList},
    routerStore,
  } = useRootStore();
  const {
    control,
    handleSubmit,
    setValue,
    formState: {errors},
    trigger,
  } = useForm<ContactDto>({resolver});

  const createContact = async (data: ContactDto) => {
    delete data?.confirmPassword;
    if(data.permission === "Coordinator") {
      const buildingClass = state.buildingClassList.find(el => el?.name === data.attribute);
      if (buildingClass) {
        data.attribute = buildingClass?.id + "";
      }
    }
    if (await create(data)) {
      browserHistory.goBack();
    }
  };
  useEffect(()=>{
    (async ()=>{
      const coordinators = await listCoordinators()
      const buildingClasses = await buildingClassList();
      const groups = await groupsList();
      setGroup(groups?.[0])
      setState( {
        listCoordinators : coordinators?.map((el:any)=>el.email),
        buildingClassList : buildingClasses,
        groupsList : groups,
      })
    })()
  },[])

  return (
    <PageLayout
      footer={<Button onClick={handleSubmit(createContact)}>create</Button>}
    >
      <div className={styles.content}>
        <h1>Create user</h1>
        <h2>User information</h2>
        <form className={styles.form}>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.name?.message}
                placeholder={"Name"}
              />
            )}
          />
          <Controller
            name="phone"
            control={control}
            defaultValue=""
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <InputMask
                mask="+1(999)999-9999"
                value={value}
                onChange={onChange}
                inputRef={ref}
                onBlur={onBlur}
                name={name}
              >
                {()=><TextFieldOutline
                errorHelper={errors.phone?.message}
                placeholder={"Phone Number"}
              />}
              </InputMask>
            )}
          />
          <Controller
            name="password"
            control={control}
            defaultValue=""
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextFieldOutline
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                type={"password"}
                errorHelper={errors.password?.message}
                placeholder={"Password"}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextFieldOutline
                inputProps={{
                  form: {
                    autocomplete: 'off',
                  },
                }}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                type={"password"}
                errorHelper={errors.confirmPassword?.message}
                placeholder={"Confirm password"}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            defaultValue=""
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                autoComplete={''}
                errorHelper={errors.email?.message}
                placeholder={"Email"}
              />
            )}
          />
          <Controller
            name="permission"
            control={control}
            defaultValue=""
            render={({field: {onChange, onBlur, value, name, ref}}) => {
              setGroup(value)
              setValue("attribute", "")
              return <TextFieldSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                items={state.groupsList.map(el=>({
                  id: el,
                  name: el
                })) || []}
                errorHelper={errors.permission?.message}
                placeholder={"Role"}
              />
            }}
          />
          {group && group != "Administrator" && <Controller
            name="attribute"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({field: {onChange, onBlur, value, name, ref}}) => (
              <TextFieldSelect
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                items={
                group === "Installer"
                  ?
                  state.listCoordinators.map(el=>({
                      id:el,
                      name:el
                  })) || []
                  :
                  state.buildingClassList || []}
                errorHelper={errors.attribute?.message &&`Select a ${group === "Installer" ? "Coordinator" : "Building Class"}`}
                placeholder={group === "Installer" ? "Coordinator" : "Building Class"}
              />
            )}
          />}
        </form>
      </div>
    </PageLayout>
  );
});
