import { PageLayout } from "@components/PageLayout/PageLayout";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useState } from "react";

import styles from "./contact.module.scss";
import { TextFieldSelect } from "@components/UI/TextFieldSelect";
import LocationIcon from "@assets/icons/icon_location.svg";

export const Contact = observer(() => {
  const [search] = useState("");
  
  const {
    contactStore: { currentContact },
    siteStore: { sites, listInstaller, listCoordinator},
    appStore: {stateList},
    routerStore,
  } = useRootStore();
  const selectAddress = async (e: any) => {
    const state = stateList.find((el)=>el.name===e?.target?.value);
    if(state && currentContact) {
      if(currentContact.permission === "Coordinator")
        await listCoordinator(state.id, currentContact?.locale ? +currentContact.locale : undefined)
      else
        await listInstaller(state.id, currentContact?.email)
    }
  };

  const siteInfo = async (id: number) => {
    if (id) await routerStore.goTo("site", { params: { id: id } });
  };
  
  return (
    <PageLayout>
      <div className={styles.content}>
        <h1>{currentContact?.name}</h1>
        <div className={styles.middle}>
          <div className={styles.contact}>
            <h3>Phone:</h3>
            <h3> {currentContact?.phone}</h3>
          </div>
          <div className={styles.contact}>
            <h3>Email:</h3>
            <h3> {currentContact?.email}</h3>
          </div>
          <div className={styles.contact}>
            <h3>GROUP:</h3>
            <h3> {currentContact?.permission}</h3>
          </div>
        </div>
        <h1>SITE LIST</h1>
        <div className={styles.search}>
            <TextFieldSelect
              onChange={(el, node) => {
                selectAddress(el);
              }}
              iconStart={LocationIcon}
              iconStartStyle={{ transform: "scale(1.2)" }}
              items={stateList}
              placeholder={"Select a State"}
            />
        </div>
        {sites
          .filter((el) => el.address.toLowerCase().includes(search))
          ?.map((el, index) => {
            return (
              <div key={index} className={styles.site} onClick={()=>siteInfo(el.id)} role={"presentation"}>
                <div className={styles.install}>
                  <h3>{el?.installPercent?.toFixed(0) || 0}%</h3>
                  <h4>installed</h4>
                </div>
                <h3>{el.address}</h3>
              </div>
            );
          })}
      </div>
    </PageLayout>
  );
});
