import InfoIcon from "@assets/icons/icon_info.svg";
import QRIcon from "@assets/icons/icon_qr.svg";
import { PageLayoutWithStepper } from "@components/PageLayoutWithStepper/PageLayoutWithStepper";
import { Button } from "@components/UI/Button";
import { ButtonIcon } from "@components/UI/ButtonIcon";
import { TextFieldOutline } from "@components/UI/TextFieldOutline";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import React, { useEffect, useRef, useState } from "react";
import InfoImage from "@assets/images/info.png"
import styles from "./activation.module.scss";
import { TextFieldSelect } from "@components/UI/TextFieldSelect";
import LocationIcon from "@assets/icons/icon_location.svg";
import CloseIcon from "@assets/icons/icon_negative.svg"
import { Icon } from "@mui/material";
import {toJS} from "mobx";

export const Activation = observer((props) => {
  const [showInfo, setShowInfo] = useState(false)
  const [refButtonInfo, setRefButtonInfo] = useState<React.MutableRefObject<HTMLDivElement>>();
  const refContentInfo = useRef<HTMLDivElement>(null)
  const {
    appStore: { scannerResult, setShowScanner , aspectRatioList, setScannerResult},
    installerStore: { installer, setInstaller, stepInstall },
    screenStore: { currentScreen },
    siteStore: { currentSite },
    routerStore,
  } = useRootStore();
  useEffect(() => {
    if (scannerResult) {
      installer.activation = scannerResult;
      setInstaller(installer);
    }
  }, [scannerResult]);
  
  const setInfoPosition = ()=>{
    if(refContentInfo.current && refButtonInfo?.current) {
      refContentInfo.current.style.top = `${refButtonInfo.current.offsetTop + refButtonInfo.current.offsetHeight}px`
      refContentInfo.current.style.left = `${8+refButtonInfo.current.offsetLeft + refButtonInfo.current.offsetWidth - refContentInfo.current.offsetWidth}px`
    }
  }
  const continueInstall = () => {
    if (installer.activation) {
      routerStore.goTo("uploadImage", {
        params: { id: currentSite?.id, screenId: currentScreen?.serverid, step: stepInstall + 1 },
      });
    }
  };
  
  const selectAspectRatio = (e:any)=>{
    const aspectRatio = aspectRatioList.find(el=>el.name === e?.target?.value);
    if(aspectRatio) {
      installer.aspectRatio = aspectRatio.id;
      setInstaller(installer);
    }
  }
  
  const showInfoContent = ()=>{

    setShowInfo(!showInfo)
    setInfoPosition()
  }

  return (
    <PageLayoutWithStepper footer={<Button onClick={() => continueInstall()}>continue</Button>}>
      <div  className={styles.content}>
        <div style={{visibility: showInfo ? 'visible' : "hidden"}} ref={refContentInfo} className={styles.helpContent}>
          <div className={styles.arrow} />
          <div className={styles.image}>
            <Icon onClick={() => setShowInfo(false)} className={styles.closeIcon} color={"secondary"}>
              close
            </Icon>
            <img src={InfoImage} alt={''} />
          </div>
        </div>
        <h1>{currentScreen?.displayname}</h1>
        <div className={styles.code}>
          <TextFieldOutline
            value={installer.activation}
            placeholder={"Enter the Activation Number"}
            iconEnd={QRIcon}
            onChange={(el)=>setScannerResult(el.target.value)}
            iconEndCb={() => setShowScanner(true)}
          />
          <div className={styles.codeHelp}>
            <ButtonIcon onClick={()=>showInfoContent()} getRef={(ref)=>{setRefButtonInfo(ref)}} icon={InfoIcon} />
          </div>
            <TextFieldSelect
              onChange={(el, node) => {
                selectAspectRatio(el);
              }}
              iconStart={LocationIcon}
              iconStartStyle={{ transform: "scale(1.2)" }}
              items={aspectRatioList}
              placeholder={"Select a aspect ratio"}
            />
        </div>
      </div>
    </PageLayoutWithStepper>
  );
});
