import * as constants from "@constants/constants";
import { BreakpointOverrides,createTheme, Icon, ThemeProvider } from "@mui/material";
import { observer } from "mobx-react-lite";
import { RouterContext, RouterView } from "mobx-state-router";
import React, { useContext, useEffect, useLayoutEffect, useRef } from "react";
import BarcodeScannerComponent from "react-qr-barcode-scanner";
import { useMediaQuery } from "react-responsive";

import styles from "./app.module.scss";
import { RootStoreContext, useRootStore } from "./context";
import { initApp } from "./init";
import { viewMap } from "./routes/viewMap";
import { Preloader } from "@components/Preloader/Preloader";
import { Snackbar } from "@components/Snackbar/Snackbar";
import { Modal } from "@components/Modal/Modal";



declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    desktop: true;
  }
}

const theme = createTheme({
  breakpoints: {
    values: {
      desktop: constants.constants.desktopWidth,
    },
  },
  palette: {
    primary: {
      main: constants.constants.primary,
    },
    secondary: {
      main: constants.constants.secondary,
    },
    success: {
      main: constants.constants.success,
    },
  },
});

const rootStore = initApp();
const { routerStore } = rootStore;


function App() {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${constants.constants.desktopWidth}px)`
  })
  useEffect(()=>{
    constants.constants.isDesktop=isDesktopOrLaptop;
  },[isDesktopOrLaptop])
  const {
    appStore: { setShowScanner, setScannerResult, showScanner, showModalInfo, setShowModalInfo, setMessage },
  } = rootStore;
  
  return (
    <>
    {showScanner && (
      <div className={styles.scanner}>
        <Icon onClick={() => setShowScanner(false)} className={styles.scannerCloseIcon} color={"primary"}>
          close
        </Icon>
        <BarcodeScannerComponent
          width={"100%"}
          height={"100%"}
          onUpdate={(err, result) => {
            if (result) {
              setScannerResult(result?.getText());
              setShowScanner(false);
            }
          }}
        />
      </div>
    )}
    <Modal setMessage={setMessage} show={showModalInfo} close={()=>setShowModalInfo(false)}/>
    <div className={styles.app} style={{filter: `blur(${showModalInfo ? 4 : 0}px)`}} >
      <ThemeProvider theme={theme}>
        <RootStoreContext.Provider value={rootStore}>
          <RouterContext.Provider value={routerStore}>
            <Preloader/>
            <Snackbar/>
            <RouterView viewMap={viewMap} />
          </RouterContext.Provider>
        </RootStoreContext.Provider>
      </ThemeProvider>
    </div>
      </>
  );
}

export default observer(App);
