import IconAccept from "@assets/icons/icon_affirmative.svg";
import IconNegative from "@assets/icons/icon_negative.svg";
import Example1 from "@assets/images/example1.png";
import { Icon } from "@components/Icon/Icon";
import { PageLayout } from "@components/PageLayout/PageLayout";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import * as React from "react";

import styles from "./screen.module.scss";

export const Screen = observer(() => {
  const {
    screenStore: { currentScreen },
    siteStore: { currentSite },
  } = useRootStore();
  
  return (
    <PageLayout>
      <div className={styles.content}>
        <h1>{currentScreen?.displayname}</h1>
        <div className={styles.header}>
          <div className={styles.helpBlock}>
            <div className={styles.help}>
              <div className={styles.blockHelp}>
                <h2>{currentScreen?.aspectratio}</h2>
                <h3>Aspect Ratio</h3>
              </div>
              <div className={styles.blockHelp}>
                <h2>{currentScreen?.lastlog}</h2>
                <h3>Last log</h3>
              </div>
            </div>
            <div className={styles.info}>
              <div className={styles.blockInfo}>
                <h3>Channel ID:</h3>
                <h3>{currentScreen?.channelid}</h3>
              </div>
              <div className={styles.blockInfo}>
                <h3>Unique ID:</h3>
                <h3>{currentScreen?.uniqueid}</h3>
              </div>
              <div className={styles.blockInfo}>
                <h3>PART:</h3>
                <h3>{currentScreen?.part}</h3>
              </div>
              <div className={styles.blockInfo}>
                <h3>INSTALL DATE:</h3>
                <h3>{currentScreen?.installdate}</h3>
              </div>
              <div className={styles.blockInfo}>
                <h3>ACTIVATION DATE:</h3>
                <h3>{currentScreen?.activationdate}</h3>
              </div>
              <div className={styles.action}>
                <Icon height={17} width={20} path={currentScreen?.monitorindicator ? IconAccept : IconNegative} />
                <h3>monitor indicator (active in om)</h3>
              </div>
              <div className={styles.action}>
                  <Icon height={17} width={20} path={currentScreen?.iscloud ? IconAccept : IconNegative} />
                <h3>isCloud</h3>
              </div>
            </div>
          </div>
          <div className={styles.description}>
            <h3>Floor #:</h3>
            <h3>{currentScreen?.locationinfo?.split(",")?.[0] || ""}</h3>
            <h3>Room #:</h3>
            <h3>{currentScreen?.locationinfo?.split(",")?.[1] || ""}</h3>
            <h3>Closest Entrance:</h3>
            <h3>{currentScreen?.locationinfo?.split(",")?.[2] || ""}</h3>
            <h3>Closest point of interest:</h3>
            <h3>{currentScreen?.locationinfo?.split(",")?.[3] || ""}</h3>
            <h3>Installer:</h3>
            <h3>{currentScreen?.locationinfo?.split(",")?.[4] || ""}</h3>
          </div>
        </div>
        {currentScreen?.photos?.length !== 0 &&
        <div className={styles.middle}>
          {currentScreen?.photos?.map((el, index) => {
            return (
              <div key={el.name} className={styles.middleBlock}>
                <div className={styles.image}>
                  <img src={el.url} alt={""} />
                </div>
              </div>
            );
          })}
        </div>}
      </div>
    </PageLayout>
  );
});
