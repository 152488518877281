import { Icon } from "@components/Icon/Icon";
import { constants, getVh, getVw } from "@constants/constants";
import {
  alpha,
  FormControl,
  FormHelperText,
  FormLabel,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  styled,
} from "@mui/material";
import { OutlinedInputProps } from "@mui/material/OutlinedInput/OutlinedInput";
import * as React from "react";
import { PropsWithChildren, useState } from "react";

const TextFieldCustom = styled(OutlinedInput)(({ theme }) => ({
  "&.MuiOutlinedInput-root": {
    border: "none",
    borderColor: theme.palette.secondary.main,
    overflow: "hidden",
    borderRadius: 2,
    fontSize: 21,
    padding: 13,
    fontFamily: constants.fontFamily,
    height: 63,
    width: "100%",
    backgroundImage: constants.backgroundImage,
    outline: "none",
    color: theme.palette.primary.main,
    boxShadow: constants.backgroundShadow,
    marginBottom: 20,
    [theme.breakpoints.up('desktop')]: {
      height: 45,
      fontSize: 14,
      lineHeight: 14,
    },
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "& textarea": {
      padding: "16.5px 14px",
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      border: "none",
      backgroundColor: "transparent",
      borderColor: theme.palette.primary.main,
    },
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
    borderColor: "transparent",
  },
  ".MuiInputAdornment-positionStart": {
    marginRight: 13,
    [theme.breakpoints.up('desktop')]: {
      height: 50,
    }
  },
  "&.Mui-error": {
    marginBottom: 0,
    backgroundImage: "-webkit-linear-gradient( 90deg, rgba(255,0,0,0.6) 0%, rgba(255,0,0,0.2) 100%)",
  },
}));

const Label = styled(FormLabel)(({ theme }) => ({
  fontSize: "10px",
  textTransform: "uppercase",
  fontWeight: "bold",
  color: theme.palette.primary.main,
}));

const FormControlCustom = styled(FormControl)(({ theme }) => ({
  "&.MuiFormControl-root": {
    width: "100%",
    ".MuiFormHelperText-root": {
      margin: 0,
      padding: 0,
      minHeight: 20,
      height: 'auto',
      bottom: 0,
      fontFamily: constants.fontFamily,
      textAlign: "right",
      color: "white",
      fontWeight: "400",
      fontStyle: "italic",
      whiteSpace: "pre-line",
    },
  },
}));

export interface TextFieldProps extends OutlinedInputProps {
  icon?: string;
  iconCb?: any;
  iconEnd?: string;
  iconEndCb?: any;
  label?: string;
  placeholder?: string;
  height?: string;
  width?: string;
  type?: string;
  errorHelper?: string;
}

export const TextFieldOutline = (props: TextFieldProps) => {
  const [value, setValue] = useState("");
  const { errorHelper, iconCb, iconEndCb, label, icon, iconEnd, height, width, ...allProps } = props;
  return (
    <FormControlCustom style={{ width: width ?? "100%" }} variant="outlined">
      <Label>{label}</Label>
      <TextFieldCustom
        {...allProps}
        autoComplete={''}
        style={{ height: height, width: width ?? "100%" }}
        onChange={(el) => {
          if (allProps.onChange) {
            allProps?.onChange(el);
          }
          setValue(el.target.value);
        }}
        startAdornment={
          icon && (
            <InputAdornment position="start" onClick={() => iconCb?.(value)}>
              <Icon path={icon} />
            </InputAdornment>
          )
        }
        endAdornment={
          iconEnd && (
            <InputAdornment position="end" onClick={() => iconEndCb?.(value)}>
              <Icon path={iconEnd} width={40} height={40} />
            </InputAdornment>
          )
        }
        error={!!errorHelper}
      />
      {!!errorHelper && <FormHelperText error>{errorHelper}</FormHelperText>}
    </FormControlCustom>
  );
};
