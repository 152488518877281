import { IsNotEmpty, IsString } from "class-validator";

export default class SupportDto {
  @IsString()
  @IsNotEmpty()
  siteName!: string;

  @IsString()
  @IsNotEmpty()
  serverName!: string;

  @IsString()
  @IsNotEmpty()
  briefDescription!: string;
}
