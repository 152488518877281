import { RootStore } from "@store/rootStore";
import { browserHistory, createRouterState, RouterState, RouterStore } from "mobx-state-router";
import { toJS } from "mobx";
const sign = createRouterState("sign");
const home = createRouterState("home");
const installerHome = createRouterState("search");
const coordinatorHome = (id: string)=>createRouterState("contact", {params: { id: id}});

const checkForUserSignedIn = async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
  const { rootStore } = routerStore.options;
  const { authStore, siteStore, appStore, contactStore } = rootStore as RootStore;
  const session = await authStore.checkToken();
  if (!session) {
    authStore.setSignInRedirect(toState);
    return sign;
  }
  await siteStore.sync()
  await appStore.sync()
  await contactStore.sync();
};

const checkForAdminOrCoordinatorSignedIn = async (
  fromState: RouterState, 
  toState: RouterState, 
  routerStore: RouterStore
) => {
  const { rootStore } = routerStore.options;
  const { authStore, siteStore, appStore, contactStore } = rootStore as RootStore;
  const session = await authStore.checkToken();
  if (!session || (!authStore.isAdmin() && !authStore.isCoordinator())) {
    authStore.setSignInRedirect(toState);
    return sign;
  }
  await siteStore.sync()
  await appStore.sync()
  await contactStore.sync();
};

const checkSession = async (
  fromState: RouterState,
  toState: RouterState,
  routerStore: RouterStore
) => {
  const { rootStore } = routerStore.options;
  const { authStore } = rootStore as RootStore;
  const session = await authStore.getCurrentSession();
  if (session) {
    return home;
  }
};


const checkForAdminSignedIn = async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
  const { rootStore } = routerStore.options;
  const { authStore, siteStore, appStore, contactStore } = rootStore as RootStore;
  const session = await authStore.checkToken();
  if (!session || !authStore.isAdmin()) {
    authStore.setSignInRedirect(toState);
    return sign;
  }
  await siteStore.sync()
  await appStore.sync()
  await contactStore.sync();
};

export const routes = [
  {
    name: "home",
    pattern: "/",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore, authStore, appStore, contactStore } = rootStore as RootStore;
      if (!authStore.isAdmin() && !authStore.isCoordinator()) {
        return installerHome;
      }
      const session = await authStore.checkToken();
      if(session && authStore.isCoordinator()){
        await contactStore.pushContact(authStore.me)
        await appStore.sync()
        return coordinatorHome(authStore.me?.id || "");
      }
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "search",
    pattern: "/search",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore, appStore, contactStore } = rootStore as RootStore;
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "sign",
    pattern: "/sign",
    beforeEnter: checkSession
  },
  {
    name: "site",
    pattern: "/site/:id",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore } = rootStore as RootStore;
      await siteStore.setCurrent(+toState.params.id);
    },
    beforeEnter: checkForAdminOrCoordinatorSignedIn,
  },
  {
    name: "contact",
    pattern: "/contact/:id",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { contactStore, siteStore } = rootStore as RootStore;
      await contactStore.setCurrent(toState.params.id);
      siteStore.clear();
    },
    beforeEnter: checkForAdminOrCoordinatorSignedIn,
  },
  {
    name: "help",
    pattern: "/help/:id",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore } = rootStore as RootStore;
      siteStore.setCurrent(+toState.params.id);
    },
    beforeEnter: checkForAdminSignedIn,
  },
  {
    name: "support",
    pattern: "/help/:id/support",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore } = rootStore as RootStore;
      siteStore.setCurrent(+toState.params.id);
    },
    beforeEnter: checkForAdminSignedIn,
  },
  {
    name: "screen",
    pattern: "/site/:id/screens/:screenId",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { screenStore, siteStore } = rootStore as RootStore;
      await siteStore.setCurrent(+toState?.params?.id);
      await screenStore.setCurrent(+toState?.params?.screenId);
    },
    beforeEnter: checkForAdminOrCoordinatorSignedIn,
  },
  {
    name: "activation",
    pattern: "/site/:id/screens/:screenId/activation",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore, screenStore, installerStore } = rootStore as RootStore;
      await siteStore.setCurrentByState(+toState?.params?.id);
      await screenStore.setCurrent(+toState?.params?.screenId);
      installerStore.goToStep(0);
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "information",
    pattern: "/site/:id/screens/:screenId/information",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { screenStore, siteStore, installerStore } = rootStore as RootStore;
      await siteStore.setCurrentByState(+toState?.params?.id);
      await screenStore.setCurrent(+toState?.params?.screenId);
      installerStore.goToStep(5);
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "installSummaryInstaller",
    pattern: "/site/:id/screens/:screenId/install-summary-installer",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { screenStore, siteStore, installerStore } = rootStore as RootStore;
      await siteStore.setCurrentByState(+toState?.params?.id);
      await screenStore.setCurrent(+toState?.params?.screenId);
      installerStore.goToStep(6);
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "finish",
    pattern: "/site/:id/screens/:screenId/finish",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { screenStore, siteStore, installerStore } = rootStore as RootStore;
      await siteStore.setCurrentByState(+toState?.params?.id);
      await screenStore.setCurrent(+toState?.params?.screenId);
      installerStore.goToStep(7);
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "uploadImage",
    pattern: "/site/:id/screens/:screenId/upload/:step",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { screenStore, siteStore, installerStore } = rootStore as RootStore;
      if (+toState?.params?.step === 5) {
        browserHistory.goBack();
        return;
      }
      await siteStore.setCurrentByState(+toState?.params?.id);
      await screenStore.setCurrent(+toState?.params?.screenId);
      installerStore.goToStep(+toState?.params?.step);
    },
    beforeEnter: checkForUserSignedIn,
  },
  {
    name: "screenCreate",
    pattern: "/site/:id/screen-create",
    onEnter: async (fromState: RouterState, toState: RouterState, routerStore: RouterStore) => {
      const { rootStore } = routerStore.options;
      const { siteStore } = rootStore as RootStore;
      siteStore.setCurrent(+toState?.params?.id);
    },
    beforeEnter: checkForAdminSignedIn,
  },
  {
    name: "contactCreate",
    pattern: "/contact-create",
    beforeEnter: checkForAdminSignedIn,
  },
  {
    name: "notFound",
    pattern: "/not-found",
  },
];
