import UserAddIcon from "@assets/icons/icon_adduser.svg";
import IconAccept from "@assets/icons/icon_affirmative.svg";
import IconNegative from "@assets/icons/icon_negative.svg";
import { Icon } from "@components/Icon/Icon";
import { PageLayout } from "@components/PageLayout/PageLayout";
import { ButtonIcon } from "@components/UI/ButtonIcon";
import { useRootStore } from "@context/index";
import { observer } from "mobx-react-lite";
import * as React from "react";

import styles from "./site.module.scss";
import { PopoverSelect } from "@components/UI/PopoverSelect";
import { useEffect, useRef, useState } from "react";
import { ContactDto } from "@models/ContactDto";
import { toJS } from "mobx";

export const Site = observer(() => {
  const menuRef = useRef(null)
  const {
    authStore: {isCoordinator, me},
    siteStore: { currentSite, installerAssign },
    contactStore: {contacts, getByEmail},
    routerStore,
  } = useRootStore();
  
  const contactInfo = async (email: string) => {
    const contact = await getByEmail(email);
    await routerStore.goTo("contact", { params: { id: contact?.id } });
  };

  const screenInfo = async (id: number | undefined, screenId: number | undefined) => {
    if (id) await routerStore.goTo("screen", { params: { id: id, screenId: screenId } });
  };

  const [openMenu, setOpenMenu] = useState(false)

  const handleClick = () => {
    setOpenMenu(true)
  };

  const handleClose = () => {
    setOpenMenu(false)
  };

  const handleSelect = async (user: ContactDto) => {
    if(currentSite) {
      await installerAssign(currentSite?.id, user.email)
      await routerStore.goTo("contact", { params: { id: user.id } });
    }
  };
  return (
    <PageLayout>
      <div className={styles.content}>
        <h1>{currentSite?.address}</h1>
        <div className={styles.help}>
          <div className={styles.blockHelp}>
            <h2>{currentSite?.screens?.length || 0}</h2>
            <h3>Displays</h3>
          </div>
          <div className={styles.blockHelp}>
            <h2>{currentSite?.installPercent.toFixed(0)}%</h2>
            <h3>Install Score</h3>
          </div>
        </div>
        <div className={styles.info}>
          <div className={styles.blockInfo}>
            <h3>Name:</h3>
            <h3>{currentSite?.sitename}</h3>
          </div>
          <div className={styles.blockInfo}>
            <h3>Market:</h3>
            <h3>{currentSite?.market}</h3>
          </div>
          <div className={styles.blockInfo}>
            <h3>Building class:</h3>
            <h3>{currentSite?.buildingclass}</h3>
          </div>
        </div>
        <div ref={menuRef} className={styles.contact}>
          <h3>Installer:</h3>
          {currentSite?.installer &&
            <h3 className={styles.contactName} onClick={()=>contactInfo(currentSite?.installer)} role={"presentation"}>{currentSite?.installer}</h3>
          }
          <ButtonIcon height={42} width={42} icon={UserAddIcon} onClick={handleClick} />
          <PopoverSelect
            itemSelect={handleSelect}
            items={isCoordinator()
              ?
              contacts.filter(el=>el.permission==="Installer" && el.locale === me.email)
              :
              contacts.filter(el=>el.permission==="Installer")}
            open={openMenu}
            anchorRef={menuRef}
            onClose={handleClose}
          />
        </div>
        <div className={styles.screens}>
          {currentSite?.screens?.map((el, index) => {
            return (
              <div
                onClick={() => screenInfo(el?.siteId, el?.serverid)}
                key={el.serverid}
                className={styles.screen}
                role={"presentation"}
              >
                <h3>{el.displayname}</h3>
                <div className={styles.action}>
                  <Icon height={17} width={20} path={el.monitorindicator ? IconAccept : IconNegative} />
                  <h3>Monitor Indicator</h3>
                </div>
                <div className={styles.action}>
                  <Icon height={17} width={20} path={el.iscloud ? IconAccept : IconNegative} />
                  <h3>ISCLOUD</h3>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </PageLayout>
  );
});
