import { PageLayout } from "@components/PageLayout/PageLayout";
import { ButtonFill } from "@components/UI/ButtonFill";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import { browserHistory } from "mobx-state-router";

import styles from "./help.module.scss";

export const Help = observer(() => {
  const {
    siteStore: { currentSite },
    routerStore,
  } = useRootStore();

  const siteSupport = async (id: number | undefined) => {
    if (id) routerStore.goTo("support", { params: { id: id } });
  };

  return (
    <PageLayout
      headerTitle={"SUPPORT"}
      footer={<ButtonFill onClick={() => browserHistory.goBack()}>RETURN</ButtonFill>}
    >
      <div className={styles.content}>
        <h1>Site Help</h1>
        <div className={styles.actions}>
          <div className={styles.action}>
            <h2>MSA</h2>
          </div>
          <div className={styles.action}>
            <h2>Invoice</h2>
          </div>
          <div className={styles.action}>
            <h2>COI</h2>
          </div>
          <div onClick={() => siteSupport(currentSite?.id)} className={styles.action} role={"presentation"}>
            <h2>Support</h2>
          </div>
        </div>
      </div>
    </PageLayout>
  );
});
