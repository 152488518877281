import { PageLayout } from "@components/PageLayout/PageLayout";
import { Button } from "@components/UI/Button";
import { TextFieldSearch } from "@components/UI/TextFieldSearch";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";

import styles from "./search.module.scss";
export const Search = observer(() => {
  const [items, setItems] = useState<string[]>([]);
  const { appStore, routerStore, authStore } = useRootStore();
  const [searchText, setSearchText] = useState("")
  const [searchTimer, setSearchTimer] = useState<NodeJS.Timeout>()
  
  const getInfo = async (item: any) => {
    if(!item){
      return
    }
    if (item.name.indexOf("Site:") > -1) {
      try {
        appStore.setLoading(true)
        await routerStore.goTo("site", { params: { id: item?.networkvenueid } });
      }finally {
        appStore.setLoading(false)
      }
      return;
    }

    if (item.name.indexOf("Display:") > -1) {
      try {
        appStore.setLoading(true)
        await routerStore.goTo("screen", { params: { id: item?.networkvenueid, screenId: item?.serverid } });
      }finally {
        appStore.setLoading(false)
      }
      return;
    }

    if (item.name.indexOf("User:") > -1) {
      try {
        appStore.setLoading(true)
        await routerStore.goTo("contact", { params: { id: item?.Username } });
      }finally {
        appStore.setLoading(false)
      }
      return;
    }
  };
  
  useEffect(()=>{
    if(searchText){
      if(searchTimer)
        clearTimeout(searchTimer)
      setSearchTimer(setTimeout(async ()=>{
        await search()
      },1500))
    }
  }, [searchText])

  const onChange = (e:any)=>{
    setSearchText(e.target.value)
   
  }
  
  const search = async ()=>{
    const searchItems = await authStore.searchItem(searchText);
    setItems([
      ...searchItems?.display?.map((el:any)=>{el.name = `Display: ${el.name}`; el.id = el.networkvenueid; return el}) || [],
      ...searchItems?.site?.map((el:any)=>{el.name = `Site: ${el.address}`; el.id = el.networkvenueid; return el}) || [],
      ...searchItems?.Users?.map((el:any)=>{el.name = `User: ${
        el.Attributes.find(( item: any )=>item['Name']==='email').Value
      }`; el.id = el.name; return el}) || [],
    ]);
  }

  return (
    <PageLayout>
      <div className={styles.page}>
        <div className={styles.content}>
          <h1>Search</h1>
          <TextFieldSearch items={items} cb={getInfo} onChange={onChange} onClick={search}/>
        </div>
      </div>
    </PageLayout>
  );
});
