import { observer } from "mobx-react-lite";
import { Button as Btn, Snackbar as SB, SnackbarOrigin, styled } from "@mui/material";
import { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useRootStore } from "@context/rootStoreContext";
import Slide, { SlideProps } from '@mui/material/Slide';
import { constants } from "@constants/constants";

type TransitionProps = Omit<SlideProps, 'direction'>;

function TransitionUp(props: TransitionProps) {
  return <Slide {...props} direction="up" />;
}

export interface State extends SnackbarOrigin {
  open: boolean;
}

export const SnackbarCustom = styled(SB)(({ theme }) => ({
  "&.MuiSnackbar-root": {
    zIndex: 9999,
    width: "auto",
    margin: "60px auto",
    fontFamily: constants.fontFamily,
    [theme.breakpoints.up('desktop')]: {
      width: "400px"
    },
  }
}))

export const Snackbar = observer(()=>{
  const {appStore: {message}}= useRootStore()

  const [state, setState] = useState<State>({
    open: false,
    vertical: 'bottom',
    horizontal: 'center',
  });

  const handleClose = () => {
    setState({ ...state, open: false });
  };
  
  const { vertical, horizontal, open} = state;

  useEffect(()=>{
    if(message) {
      setState({ ...state, open: true });
    }
  },[message])
  
  if(!message){
    return null;
  }
  return (
    <SnackbarCustom
      anchorOrigin={{ vertical, horizontal }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={message}
      TransitionComponent={TransitionUp}
      key={Math.random()+10}
      action={<IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>}
    />
  )
})