import SiteApi, { SiteInfo } from "@services/siteApi";
import { RootStore } from "@store/rootStore";
import { makeAutoObservable, runInAction, toJS } from "mobx";
import AuthApi from "@services/authApi";

export interface ISiteStore {
  get(id: number): Promise<SiteInfo | undefined>;
  list(): Promise<SiteInfo[]>;
  create(siteDto: SiteInfo): Promise<SiteInfo>;
  update(siteDto: SiteInfo, id: string): Promise<SiteInfo>;
  sync(): Promise<void>;
  setCurrent(id: number): Promise<void>;
}

export class SiteStore implements ISiteStore {
  public sites: SiteInfo[] = [];
  public currentSite: SiteInfo | undefined | null = undefined;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get = async (id: number): Promise<SiteInfo | undefined> => {
    return this.sites.find((el) => el.id === id);
  };

  list = async (predicate?: (value: SiteInfo, index: number, array: SiteInfo[]) => unknown): Promise<SiteInfo[]> => {
    try {
      this.rootStore.appStore.setLoading(true)
      return this.sites;
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
      return this.sites;
    }finally {
      this.rootStore.appStore.setLoading(false)
    }
  };

  installerAssign = async (siteId: number, installerEmail: string): Promise<void> =>{
      try {
        this.rootStore.appStore.setLoading(true)
        await SiteApi.installerAssign(siteId, installerEmail)
      }catch (e: any) {
        this.rootStore.appStore.setMessage(e)
      }finally {
        this.rootStore.appStore.setLoading(false)
      }
  }

  listInstaller = async (stateId: number, email?: string): Promise<SiteInfo[]> => {
    try {
      this.rootStore.appStore.setLoading(true)
      const sites = await SiteApi.listByInstaller(email || this.rootStore.authStore.email, stateId);
      this.sites = sites.sort(function(a, b) {
        return a.address.localeCompare(b.address, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      })
      return this.sites;
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
      return this.sites;
    }finally {
      this.rootStore.appStore.setLoading(false)
    }
  };

  listCoordinator = async (stateId: number, buildingclassId?: number): Promise<SiteInfo[]> => {
    try {
      this.rootStore.appStore.setLoading(true)
      const locale = this?.rootStore?.authStore?.me?.locale || 0;
      const sites = await SiteApi.listByCoordinator(buildingclassId || +locale, stateId);
      this.sites = sites.sort(function(a, b) {
        return a.address.localeCompare(b.address, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      })
      return this.sites;
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
      return this.sites;
    }finally {
      this.rootStore.appStore.setLoading(false)
    }
  };

  update = async (siteDto: SiteInfo, id: string): Promise<SiteInfo> => {
    return siteDto;
  };

  sync = async (): Promise<void> => {
    try {
      this.rootStore.appStore.loading = true;
      // this.sites = await SiteApi.list();
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.loading = false;
    }
  };

  setCurrent = async (id: number): Promise<void> => {
    try {
      this.rootStore.appStore.loading = true;
      this.currentSite = await SiteApi.get(id);
      await this.rootStore.screenStore.setScreens(this.currentSite?.screens ?? []);
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.loading = false;
    }
  };

  setCurrentByState = async (id: number): Promise<void> => {
    try {
      this.rootStore.appStore.loading = true;
      this.currentSite = await this.sites.find(el=>el.id===id)
      this.rootStore.screenStore.screens = this.currentSite?.screens ?? [];
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.loading = false;
    }
  };

  create(siteDto: SiteInfo): Promise<SiteInfo> {
    return Promise.resolve(new SiteInfo());
  }

  clear = ()=>{
    this.sites = [];
    this.currentSite = null;
  }
}
