import { Icon } from "@components/Icon/Icon";
import { constants, getVh, getVw } from "@constants/constants";
import { KeyboardArrowDown } from "@mui/icons-material";
import {
  createStyles,
  FormControl,
  FormHelperText,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
  SelectProps,
  styled,
} from "@mui/material";
import * as React from "react";
import { CSSProperties } from "react";
import { toJS } from "mobx";

const Label = styled(FormLabel)(({ theme }) => ({
  fontSize: "10px",
  textTransform: "uppercase",
  fontWeight: "bold",
  color: theme.palette.primary.main + " !important",
}));

const FormControlCustom = styled(FormControl)(({ theme }) => ({
  "&.MuiFormControl-root": {
    width: "100%",
    ".MuiFormHelperText-root": {
      margin: 0,
      padding: 0,
      height: 20,
      textAlign: "right",
      fontWeight: "400",
      fontStyle: "italic",
      color: "white",
    },
  },
}));

const SelectCustom = styled(Select)(({ theme }) => ({
  "&.MuiOutlinedInput-root": {
    border: "none",
    overflow: "hidden",
    borderRadius: 2,
    fontSize: 21,
    padding: 5,
    height: 63,
    [theme.breakpoints.up('desktop')]: {
      height: 45,
      fontSize: 14,
    },
    width: "100%",
    fontFamily: constants.fontFamily,
    backgroundImage: constants.backgroundImage,
    boxShadow: constants.backgroundShadow,
    outline: "none",
    color: theme.palette.primary.main,
    marginBottom: 20,
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&.Mui-focused": {
      border: "none",
      backgroundColor: "transparent",
    },
  },
  ".MuiSvgIcon-root": {
    color: theme.palette.primary.main,
  },
  ".MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "	.MuiSelect-icon": {
    fontSize: getVw(30),
    [theme.breakpoints.up('desktop')]: {
      fontSize: 20,
    },
  },
  "&.Mui-error": {
    marginBottom: 0,
    backgroundImage: "-webkit-linear-gradient( 90deg, rgba(255,0,0,0.6) 0%, rgba(255,0,0,0.2) 100%)",
  },
}));

const MenuItemCustom = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    color: theme.palette.primary.main,
    fontSize: getVw(21),
    minHeight: "10px",
    padding: "10px",
    fontFamily: constants.fontFamily,
    textTransform: "capitalize",
    margin: 0,
    [theme.breakpoints.up('desktop')]: {
      fontSize: 14,
    },
  },
  "&.Mui-selected": {
    // backgroundColor: "rgba(0,0,0,0.4)",
  },
  "&.Mui-disabled": {
    opacity: "1",
  },
}));

const IconStyle: CSSProperties = {
  position: "absolute",
  zIndex: 1,
  margin: 'auto auto',
  left: getVw(15),
  top: '20%',
};

interface SelectCustomProps extends SelectProps {
  label?: string;
  width?: string;
  items?: any;
  errorHelper?: string;
  iconStart?: string;
  iconStartStyle?: CSSProperties | undefined;
}

export const TextFieldSelect = (props: SelectCustomProps) => {
  const { width, items, errorHelper, ref, label, iconStart, iconStartStyle, ...allProps } = props;
  return (
    <FormControlCustom fullWidth style={{ width: width ?? "100%" }} variant="outlined" error={!!errorHelper}>
      <Label>{label}</Label>
      {iconStart && <Icon style={{ ...IconStyle, ...iconStartStyle }} path={iconStart} />}
      <SelectCustom
        {...allProps}
        variant={"outlined"}
        displayEmpty
        IconComponent={KeyboardArrowDown}
        inputProps={{ "aria-label": "Without label" }}
        renderValue={(selected: any) => {
          if (!selected) {
            return (
              <span style={{ opacity: "0.4", marginLeft: iconStart && getVw(30) }}>{props?.placeholder ?? ""}</span>
            );
          }
          return <span style={{ marginLeft: iconStart && getVw(30) }}>{selected}</span>;
        }}
        MenuProps={{
          PaperProps: {
            sx: {
              border: `none`,
              borderRadius: "2px",
              borderTop: "none",
              marginTop: getVh(0),
              padding: "0 2px",
              backgroundColor: "transparent",
              backgroundImage: "-webkit-linear-gradient( 90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.6) 100%)",
              boxShadow: "0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33)",
            },
          },
        }}
      >
        {/*{props?.placeholder && (*/}
        {/*  <MenuItemCustom disabled value={""} disableRipple>*/}
        {/*    <span style={{ opacity: "0.4" }}>{props?.placeholder ?? ""}</span>*/}
        {/*  </MenuItemCustom>*/}
        {/*)}*/}
        {items?.map((el: any) => {
          return <MenuItemCustom key={el.id} value={el.name} data-id={el.id}>
            {el.name}
          </MenuItemCustom>
        })}
      </SelectCustom>
      {!!errorHelper && <FormHelperText error>{errorHelper}</FormHelperText>}
    </FormControlCustom>
  );
};
