import { constants, getVh, getVw } from "@constants/constants";
import { CSSProperties, PropsWithChildren } from "react";
import { useMediaQuery } from "react-responsive";

import styles from "./Icon.module.scss";

interface IconProps extends PropsWithChildren<any> {
  path: string;
  width?: number;
  height?: number;
  style?: CSSProperties | undefined;
}
export const Icon = (props: IconProps) => {
  return (
    <div
      className={styles.icon}
      style={{ ...(props.style ?? {}), ...{ width: getVw(props.width ?? 21), height: getVh(props.height ?? 26) } }}
    >
      {props.path && <img className={styles.image} src={props.path} alt={""} />}
    </div>
  );
};
