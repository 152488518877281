import { API, Auth } from "aws-amplify";
import { daDK } from "@mui/material/locale";

class Http {
  public async post(apiName: string, path: string, data: any, headers?: any): Promise<any> {
    try {
      return await API.post(apiName, path, {
        headers: headers || {
          'Content-Type': 'application/json',
        },
        body: {
          ...data,
        },
      });
    }catch (e){
      console.error(e)
      throw e;
    }
  }

  public async put(apiName: string, path: string, data: any, headers?: any): Promise<any> {
    try {
      return await API.put(apiName, path, {
        headers: headers || {
          'Content-Type': 'application/json',
        },
        body: {
          ...data,
        },
      });
    }catch (e){
      console.error(e)
      throw e;
    }
  }
  
  public async get(apiName: string, path: string, data: any, headers?: any): Promise<any> {
    if(data) {
      const params = new URLSearchParams(Object.entries(data))
      path+= `?${params.toString()}`
    }
    try {
      return await API.get(apiName, path, {
        headers: headers || {
          'Content-Type': 'application/json',
        }
      });
    }catch (e){
      console.error(e)
      throw e;
    }
  }
}

export default new Http();
