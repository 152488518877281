import { PageLayoutWithStepper } from "@components/PageLayoutWithStepper/PageLayoutWithStepper";
import { Button } from "@components/UI/Button";
import { TextFieldOutline } from "@components/UI/TextFieldOutline";
import { useRootStore } from "@context/rootStoreContext";
import { classValidatorResolver } from "@hookform/resolvers/class-validator/dist/class-validator";
import { ScreenInformationDto } from "@models/ScreenInformationDto";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import styles from "./information.module.scss";

const resolver = classValidatorResolver(ScreenInformationDto);

export const Information = observer(() => {
  const {
    installerStore: { installer, setInstaller },
    screenStore: { currentScreen },
    siteStore: { currentSite },
    routerStore,
  } = useRootStore();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ScreenInformationDto>({ resolver });

  const createScreen = async (data: ScreenInformationDto) => {
    installer.installerName = data.installerName;
    installer.floor = data.floor;
    installer.room = data.room;
    installer.closestEntrance = data.closestEntrance;
    installer.closestPointOfInterest = data.closestPointOfInterest;
    setInstaller(installer);
    routerStore.goTo("installSummaryInstaller", {
      params: { id: currentSite?.id, screenId: currentScreen?.serverid },
    });
  };

  return (
    <PageLayoutWithStepper
      footer={
        <Button disabled={!installer.activation} onClick={handleSubmit(createScreen)}>
          continue
        </Button>
      }
    >
      <div className={styles.content}>
        <h1>{currentScreen?.displayname}</h1>
        <h2>Screen Infomation</h2>
        <form className={styles.form}>
          <Controller
            name="floor"
            control={control}
            defaultValue={installer.floor}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.floor?.message}
                // label={"FLOOR#"}
                placeholder={"Enter floor #"}
              />
            )}
          />
          <Controller
            name="room"
            control={control}
            defaultValue={installer.room}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.room?.message}
                // label={"ROOM#"}
                placeholder={"Enter room #"}
              />
            )}
          />
          <Controller
            name="closestEntrance"
            control={control}
            defaultValue={installer.closestEntrance}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.closestEntrance?.message}
                // label={"CLOSEST ENTRANCE"}
                placeholder={"Enter entrance information"}
              />
            )}
          />
          <Controller
            name="closestPointOfInterest"
            control={control}
            defaultValue={installer.closestPointOfInterest}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.closestPointOfInterest?.message}
                // label={"CLOSEST POINT OF INTEREST"}
                placeholder={"Enter nearby information"}
              />
            )}
          />
          <Controller
            name="installerName"
            control={control}
            defaultValue={installer.installerName}
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldOutline
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                name={name}
                inputRef={ref}
                errorHelper={errors.installerName?.message}
                // label={"INSTALLER NAME"}
                placeholder={"Enter name of Installer"}
              />
            )}
          />
        </form>
      </div>
    </PageLayoutWithStepper>
  );
});
