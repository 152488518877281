import { constants, getVh, getVw } from "@constants/constants";
import {
  Step,
  StepIconProps,
  StepLabel,
  Stepper as StepperMui,
  StepperProps as StepperPropsMui,
  styled,
} from "@mui/material";

const StepCustom = styled(Step)(({ theme }) => ({
  "&.MuiStep-root": {
    padding: "0 0px",
    ".MuiStepConnector-root": {
      opacity: 0,
    },
  },
}));

const StepLabelCustom = styled(StepLabel)(({ theme }) => ({
  "&.MuiStepLabel-root": {
    ".MuiStepLabel-labelContainer": {
      padding: "0 0px",
      ".MuiStepLabel-label": {
        fontSize: "0px",
      },
    },
  },
}));

const StepIconRoot = styled("div")(({ theme }) => ({
  width: 35,
  height: 35,
  border: "2px solid",
  boxShadow: "0px 0px 9.4px 0.6px rgba(0, 0, 0, 0.44)",
  borderColor: theme.palette.primary.main,
  borderRadius: "50%",
  cursor: "pointer",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.main,
  fontFamily: constants.fontFamily,
  fontSize: 22,
  boxSizing: "border-box",
  paddingTop: 3,
  [theme.breakpoints.up('desktop')]: {
    height: 30,
    width: 30,
    fontSize: 18,
  },
  "&.StepIcon-completedIcon": {
    borderColor: theme.palette.secondary.main,
    backgroundColor: theme.palette.secondary.main,
  },
  "&.StepIcon-active": {
    borderColor: theme.palette.secondary.main,
  },
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;

  return (
    <StepIconRoot
      className={`${className} ${completed ? "StepIcon-completedIcon" : ""} ${active ? "StepIcon-active" : ""}`}
    >
      {icon}
    </StepIconRoot>
  );
}

const StepperCustom = styled(StepperMui)(({ theme }) => ({
  "&.MuiStepper-root": {
    width: "100%",
  },
}));

interface StepperProps extends StepperPropsMui {
  steps: any;
  activeStep: number;
  onClickStep?: any;
}

export const Stepper = (props: StepperProps) => {
  return (
    <StepperCustom activeStep={props?.activeStep ?? 0} alternativeLabel>
      {props?.steps?.map((label: string, index: number) => (
        <StepCustom key={label} onClick={() => props?.onClickStep?.(index)}>
          <StepLabelCustom StepIconComponent={StepIcon}></StepLabelCustom>
        </StepCustom>
      ))}
    </StepperCustom>
  );
};
