import { observer } from "mobx-react-lite";
import { CircularProgress } from "@mui/material";
import styles from "./Preloader.module.scss"
import { useRootStore } from "@context/rootStoreContext";

export const Preloader = observer(()=>{
  const {appStore: { loading }} = useRootStore()
  if(!loading){
    return null
  }
  return (
    <div className={styles.content}>
        <CircularProgress color={"secondary"} />
    </div>
  )
})