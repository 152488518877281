import {
  Activation,
  Contact,
  ContactCreate,
  Help,
  Information,
  InstallFinish,
  InstallSummaryInstaller,
  Screen,
  ScreenCreate,
  Search,
  SearchInstaller,
  Sign,
  Site,
  Support,
  UploadImage,
} from "@pages/index";
import React from "react";
export const viewMap = {
  sign: <Sign />,
  home: <Search />,
  site: <Site />,
  screen: <Screen />,
  screenCreate: <ScreenCreate />,
  contactCreate: <ContactCreate />,
  contact: <Contact />,
  support: <Support />,
  help: <Help />,
  search: <SearchInstaller />,
  activation: <Activation />,
  uploadImage: <UploadImage />,
  information: <Information />,
  installSummaryInstaller: <InstallSummaryInstaller />,
  finish: <InstallFinish />,
};
