import Logo from "@assets/captivate_logo.svg";
import IconBack from "@assets/icons/icon_back.svg";
import IconMenu from "@assets/icons/icon_menu.svg";
import { ButtonIcon } from "@components/UI/ButtonIcon";
import { constants } from "@constants/constants";
import { observer } from "mobx-react-lite";
import { browserHistory } from "mobx-state-router";
import { PropsWithChildren, useEffect, useRef } from "react";
import { useMediaQuery } from 'react-responsive'

import { useRootStore } from "../../context";
import style from "./Header.module.scss";

interface HeaderProps extends PropsWithChildren<any> {
  title?: string;
}

export const Header = observer((props: HeaderProps) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${constants.desktopWidth}px)`
  })
  const ref = useRef<HTMLDivElement>(null);
  const {
    appStore: { menu, setMenu },
    authStore: {me},
    routerStore
  } = useRootStore();

  useEffect(()=>{
      setMenu(isDesktopOrLaptop)
  },[isDesktopOrLaptop])
  
  const goHome = async ()=>{
      await routerStore.goTo("home")
  }
  return (
    <div ref={ref} className={style.header}>
      <div className={style.content}>
        {me?.id && <ButtonIcon width={35} height={35} icon={IconBack} onClick={() => browserHistory.goBack()} />}
        <div className={style.logo} onClick={()=>goHome()} role={"presentation"}>
          <img src={Logo} alt={""} />
        </div>
        {me?.id &&<ButtonIcon
          icon={!menu ? IconMenu : ""}
          width={35}
          height={35}
          onClick={() => {
            setMenu(true);
          }}
        />}
      </div>
    </div>
  );
});
