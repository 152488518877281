import IconMenu from "@assets/icons/icon_menu.svg";
import HelpIcon from "@assets/icons/icon_help.svg";
import LogOutIcon from "@assets/icons/icon_logout.svg";
import AddUserIcon from "@assets/icons/icon_adduser.svg";
import QRIcon from "@assets/icons/icon_qr.svg";
import { ButtonIcon } from "@components/UI/ButtonIcon";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";

import styles from "./Menu.module.scss";

export const Menu = observer(() => {
  const {
    appStore: { menu, setMenu, setShowModalInfo },
    authStore: { me , isCoordinator, isInstaller, isAdmin, logout},
    routerStore
  } = useRootStore();
  if (!menu || !me?.id) {
    return null;
  }
  
  const goInstall = async ()=>{
    await routerStore.goTo("search")
  }

  const logoutAction = async ()=>{
    await logout()
    await routerStore.goTo("sign")
  }

  const createContact = async () => {
    await routerStore.goTo("contactCreate");
  };

  return (
    <div className={styles.menu}>
      <div className={styles.content}>
        <ButtonIcon icon={IconMenu} width={35} height={35} onClick={() => { setMenu(false);}}/>
        {(isCoordinator() || isInstaller()) &&
        <ButtonIcon onClick={()=>goInstall()} width={25} height={30} icon={QRIcon} title={"Install"}/>}
        {(!isCoordinator() && isAdmin()) && <ButtonIcon onClick={()=>createContact()} width={25} height={30} icon={AddUserIcon} title={"Add User"}/>}
        <ButtonIcon onClick={()=>setShowModalInfo(true)} width={30} height={30} icon={HelpIcon} title={"Help"} />
        <ButtonIcon onClick={()=>logoutAction()} width={45} height={30} icon={LogOutIcon} title={"Log Out"} />
      </div>
    </div>
  );
});
