import { PageLayoutWithStepper } from "@components/PageLayoutWithStepper/PageLayoutWithStepper";
import { Button } from "@components/UI/Button";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import * as React from "react";

import styles from "./installSummaryInstaller.module.scss";

const imageCaption = [
  "Screen only",
  "5-10ft away",
  "Screen with person",
  "Environment w/ screen",
  "Environment w/o screen",
];

export const InstallSummaryInstaller = observer(() => {
  const {
    installerStore: { installer },
    screenStore: { currentScreen },
    siteStore: { currentSite },
    appStore: {aspectRatioList},
    routerStore,
  } = useRootStore();

  const {
    installerStore
  } = useRootStore();
  
  const continueInstall = async () => {
    const isInstall = await installerStore.install();
    if(isInstall) {
      await routerStore.goTo("finish", {
        params: { id: currentSite?.id, screenId: currentScreen?.serverid },
      });
    }
  };
  return (
    <PageLayoutWithStepper
      footer={
        <Button disabled={!installer.activation} onClick={() => continueInstall()}>
          submit
        </Button>
      }
    >
      <div className={styles.content}>
        <h1>{currentScreen?.displayname}</h1>
        <h2>Install Summary</h2>
        <div className={styles.info}>
          <div className={styles.description}>
            <h3>Aspect ratio:</h3>
            <h3>{aspectRatioList.find(el=>el.id===installer.aspectRatio)?.name || ""}</h3>
            <h3>Floor #:</h3>
            <h3>{installer.floor}</h3>
            <h3>Room #:</h3>
            <h3>{installer.room}</h3>
            <h3>Closest Entrance:</h3>
            <h3>{installer.closestEntrance}</h3>
            <h3>Closest point of interest:</h3>
            <h3>{installer.closestPointOfInterest}</h3>
            <h3>Installer:</h3>
            <h3>{installer.installerName}</h3>
          </div>
          <div className={styles.middle}>
            {installer?.images?.map((el, index) => {
              return (
                <div key={el} className={styles.middleBlock}>
                  <div className={styles.image}>
                    <img src={el.content} alt={""} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </PageLayoutWithStepper>
  );
});
