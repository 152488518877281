import { Button, styled } from "@mui/material";

export const ButtonFill = styled(Button)(({ theme }) => ({
  color: "white",
  width: "100%",
  height: "49px",
  fontSize: "9px",
  lineHeight: "0",
  fontWeight: "bold",
  backgroundColor: theme.palette.primary.main,
  border: "2px solid",
  borderColor: theme.palette.primary.main,
  "&:hover": {
    color: theme.palette.primary.main,
    backgroundColor: "white",
  },
}));

export const ButtonFillSuccess = styled(Button)(({ theme }) => ({
  color: "white",
  width: "100%",
  height: "49px",
  fontSize: "9px",
  lineHeight: "0",
  fontWeight: "bold",
  backgroundColor: theme.palette.success.main,
  border: "2px solid",
  borderColor: theme.palette.success.main,
  "&:hover": {
    color: theme.palette.success.main,
    backgroundColor: "white",
  },
}));
