import { PageLayout } from "@components/PageLayout/PageLayout";
import { ButtonFill } from "@components/UI/ButtonFill";
import { TextFieldOutline } from "@components/UI/TextFieldOutline";
import { TextFieldSelect } from "@components/UI/TextFieldSelect";
import { useRootStore } from "@context/rootStoreContext";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { ScreenDto } from "@models/ScreenDto";
import { observer } from "mobx-react-lite";
import { browserHistory } from "mobx-state-router";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";

import styles from "./screenCreate.module.scss";
import { ScreenInfo } from "@services/screenApi";

const resolver = classValidatorResolver(ScreenDto);

export const ScreenCreate = observer(() => {
  const {
    siteStore: { currentSite },
    screenStore: { networkTypes, },
    routerStore,
  } = useRootStore();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ScreenDto>({ resolver });

  const createScreen = async (data: ScreenDto) => {
    // const screen = data as ScreenInfo;
    // screen.siteId = currentSite?.id ?? 1;
    // const res = await create(screen);
    // routerStore.goTo("screen", { params: { id: screen.siteId, screenId: res.id } });
  };
  return null;
  // return (
  //   <PageLayout
  //     headerTitle={currentSite?.sitename}
  //     footer={<ButtonFill onClick={handleSubmit(createScreen)}>create screen</ButtonFill>}
  //   >
  //     <div className={styles.content}>
  //       <h1>Screens</h1>
  //       <form className={styles.form}>
  //         <Controller
  //           name="screenname"
  //           control={control}
  //           defaultValue=""
  //           render={({ field: { onChange, onBlur, value, name, ref } }) => (
  //             <TextFieldOutline
  //               onChange={onChange}
  //               onBlur={onBlur}
  //               value={value}
  //               name={name}
  //               inputRef={ref}
  //               errorHelper={errors.screenname?.message}
  //               label={"SCREEN NAME"}
  //               placeholder={"Enter a screen name"}
  //             />
  //           )}
  //         />
  //         <Controller
  //           name="networktype"
  //           control={control}
  //           defaultValue=""
  //           render={({ field: { onChange, onBlur, value, name, ref } }) => (
  //             <TextFieldSelect
  //               onChange={onChange}
  //               onBlur={onBlur}
  //               value={value}
  //               name={name}
  //               inputRef={ref}
  //               items={networkTypes()}
  //               errorHelper={errors.networktype?.message}
  //               label={"NETWORK TYPE"}
  //               placeholder={"Select a network type"}
  //             />
  //           )}
  //         />
  //         <Controller
  //           name="channelid"
  //           control={control}
  //           defaultValue=""
  //           render={({ field: { onChange, onBlur, value, name, ref } }) => (
  //             <TextFieldOutline
  //               onChange={onChange}
  //               onBlur={onBlur}
  //               value={value}
  //               name={name}
  //               inputRef={ref}
  //               errorHelper={errors.channelid?.message}
  //               label={"CHANNEL ID"}
  //               placeholder={"Enter a channel id"}
  //             />
  //           )}
  //         />
  //         <Controller
  //           name="macaddress"
  //           control={control}
  //           defaultValue=""
  //           render={({ field: { onChange, onBlur, value, name, ref } }) => (
  //             <TextFieldOutline
  //               onChange={onChange}
  //               onBlur={onBlur}
  //               value={value}
  //               name={name}
  //               inputRef={ref}
  //               errorHelper={errors.macaddress?.message}
  //               label={"MAC ADDRESS"}
  //               placeholder={"Enter a MAC address"}
  //             />
  //           )}
  //         />
  //         <Controller
  //           name="part"
  //           control={control}
  //           defaultValue=""
  //           render={({ field: { onChange, onBlur, value, name, ref } }) => (
  //             <TextFieldOutline
  //               onChange={onChange}
  //               onBlur={onBlur}
  //               value={value}
  //               name={name}
  //               inputRef={ref}
  //               errorHelper={errors.part?.message}
  //               label={"PART"}
  //               placeholder={"Enter a part"}
  //             />
  //           )}
  //         />
  //       </form>
  //     </div>
  //   </PageLayout>
  // );
});
