import styles from "./Modal.module.scss"
import { ButtonIcon } from "@components/UI/ButtonIcon";
import ClipboardIcon from "@assets/icons/icon_clipboard.svg"
import { observer } from "mobx-react-lite";
import { useRootStore } from "@context/rootStoreContext";
import { PropsWithChildren } from "react";
interface PropsModal extends PropsWithChildren<any>{
  show: boolean,
  close: ()=> void;
  setMessage: (message: string)=> void
}
export const Modal = observer((props:PropsModal)=>{
  
  const phone = "978.845.5400"
  if(!props.show){
    return null;
  }

  async function copy(e: any) {
    e.stopPropagation();
    await navigator.clipboard.writeText(phone);
    await props.setMessage("Copied to clipboard");
    props.close()
  }
  
  const onCLose = (e: any)=>{
    e.stopPropagation();
    props?.close();
  }
  
  return (
    <div className={styles.modal} onClick={onCLose} role={"presentation"}>
      <div className={styles.content} onClick={copy} role={"presentation"}>
          <span>Captivate Network Operations Center</span>
          <div className={styles.controls}>
            <span>{phone}</span>
            <ButtonIcon icon={ClipboardIcon}/>
          </div>
      </div>
    </div>
  )
})