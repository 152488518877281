import { IsEmail, IsNotEmpty, IsString } from "class-validator";

export class SearchInstallerDto {
  displayid: string;

  @IsString()
  @IsNotEmpty()
  city!: string;
  
  @IsString()
  @IsNotEmpty()
  site!: string;

  @IsString()
  @IsNotEmpty()
  screen!: string;
}
