import { ContactDto } from "@models/ContactDto";
import ContactApi, { Permissions } from "@services/contactApi";
import SiteApi from "@services/siteApi";
import { RootStore } from "@store/rootStore";
import { makeAutoObservable } from "mobx";

export interface IContactStore {
  get(id: string): Promise<ContactDto | undefined>;
  create(siteDto: ContactDto): Promise<ContactDto | undefined>;
}

export class ContactStore implements IContactStore {
  public contacts: ContactDto[] = [];
  public permissions: string[] = [];
  public currentContact: ContactDto | undefined | null = undefined;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  create = async (contactDto: ContactDto): Promise<ContactDto | undefined> => {
    try {
      this.rootStore.appStore.setLoading(true);
      return await ContactApi.create(contactDto);
    }catch (e:any) {
      this.rootStore.appStore.setMessage(e);
    } finally {
      this.rootStore.appStore.setLoading(false);
    }
  };

  get = async (id: string): Promise<ContactDto | undefined> => {
    return this.contacts.find(el=>el.id===id)
  };

  getByEmail = async (email: string): Promise<ContactDto | undefined> => {
    return this.contacts.find(el=>el.email===email)
  };

  listInstaller = async () => {
    return await ContactApi.installersList();
  };

  listCoordinators = async () => {
    return await ContactApi.coordinatorsList();
  };

  buildingClassList = async () => {
    return await ContactApi.buildingClassList();
  };

  groupsList = async () =>{
    return await ContactApi.groupsList();
  }

  setCurrent = async (id: string): Promise<void> => {
    this.currentContact = await this.get(id);
  };

  pushContact = async (contact: ContactDto): Promise<void> => {
    this.contacts.push(contact);
  };

  sync = async ()=>{
    try {
      this.contacts = [];
      if(this.rootStore.authStore.isInstaller()){
        return this.contacts;
      }
      this.rootStore.appStore.setLoading(true)
      const coordinators = await this.listCoordinators();
      const installers = await this.listInstaller();
      this.contacts.push(...coordinators)
      this.contacts.push(...installers)
      return this.contacts;
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.setLoading(false)
    }
  }
}
