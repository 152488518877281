import { ContactDto } from "@models/ContactDto";
import Amplify from "@utils/amplify"
import { Auth } from "aws-amplify";
export const Permissions = ["Installer", "Administrator", ""];

export interface BuildingClass {
  name: string
  id: number
}

export default class ContactApi {
  public static async create(contact: ContactDto): Promise<ContactDto> {
    const Authorization = (await Auth.currentSession()).getAccessToken().getJwtToken();

    contact.phone = contact.phone.replace(/[&\/\\#,\-()$~%.'":*?<>{}]/g, "")

    const data = await Amplify.post('AdminQueries', '/createUser', contact, {
      'Content-Type': 'application/json',
      Authorization,
    });

    await Amplify.post('AdminQueries', '/addUserToGroup', {
      username: contact.email,
      groupname: contact.permission,
    }, {
      'Content-Type': 'application/json',
      Authorization,
    });

    if(contact.permission === "Coordinator"){
      await Amplify.post('AdminQueries', '/addUserToGroup', {
        username: contact.email,
        groupname: "Administrator",
      },{
        'Content-Type': 'application/json',
        Authorization
      })
    }

    if(contact.attribute) {
      await Amplify.post('AdminQueries', '/setUserAttribute', {
        email: contact.email,
        attributeName: "locale",
        attributeValue: contact.attribute,
      }, {
        'Content-Type': 'application/json',
        Authorization,
      });
    }

    return data;
  }

  public static async installersList(){
    const res = await Amplify.get('AdminQueries', '/listUsersInGroup', {
      groupname: "Installer",
    }, {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    })
    let users = res?.Users?.map((el: any)=>{
      return {
        email:el?.Attributes?.find((el:any)=>el.Name==='email')?.Value,
        phone:el?.Attributes?.find((el:any)=>el.Name==='phone_number')?.Value,
        permission : "Installer",
        locale: el?.Attributes?.find((el:any)=>el.Name==='locale')?.Value,
        id: el?.Attributes?.find((el:any)=>el.Name==='sub')?.Value,
        name: el?.Attributes?.find((el:any)=>el.Name==='name')?.Value,
    }
  })
    users = users.sort((a:any, b: any) => a.email > b.email ? 1 : a.email < b.email ? -1 : 0)
    return users || [];
  }

  public static async coordinatorsList(){
    const res = await Amplify.get('AdminQueries', '/listUsersInGroup', {
      groupname: "Coordinator",
    }, {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    })
    let users = res?.Users?.map((el: any)=> {
      return {
        email: el?.Attributes?.find((el: any) => el.Name === 'email')?.Value,
        phone: el?.Attributes?.find((el: any) => el.Name === 'phone_number')?.Value,
        permission: "Coordinator",
        locale: el?.Attributes?.find((el:any)=>el.Name==='locale')?.Value,
        id: el?.Attributes?.find((el: any) => el.Name === 'sub')?.Value,
        name: el?.Attributes?.find((el: any) => el.Name === 'name')?.Value,
      }
    })
    users = users.sort((a:any, b: any) => a.email > b.email ? 1 : a.email < b.email ? -1 : 0)
    return users || [];
  }

  public static async buildingClassList() : Promise<BuildingClass[]>{
    const res = await Amplify.get('portalAPI', '/buildingclasslist', {})
    if(res?.error?.length){
      throw res.error.join(",")
    }
    return res?.data || [];
  }

  public static async groupsList(){
    const res = await Amplify.get('AdminQueries', '/listGroups', {}, {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
    })
    return res?.Groups?.map?.((el:any)=>el.GroupName) || [];
  }
}
