import { makeAutoObservable, runInAction } from "mobx";
import { browserHistory, createRouterState, HistoryAdapter, RouterStore } from "mobx-state-router";

import { RootStore } from "./rootStore";
import AppApi, { AspectRatioInfo, StateInfo } from "@services/appApi";
import SiteApi from "@services/siteApi";

export interface IAppStore {
  setLoading(enable: boolean, title?: string): void;
}

export enum ThemeType {
  light = "light",
  dark = "dark",
}

export default class AppStore implements IAppStore {
  public loading = false;
  public message: string | null;
  public theme: ThemeType = ThemeType.light;
  public showScanner = false;
  public scannerResult = "";
  public showModalInfo = false;
  public menu = false;
  public aspectRatioList: AspectRatioInfo[] = [];
  public stateList: StateInfo[] = []
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  setTheme = async (theme: ThemeType) => {
    this.theme = theme;
  };

  setMenu = async (show: boolean) => {
    this.menu = show;
  };

  setShowScanner = (show: boolean) => {
    this.showScanner = show;
  };

  setShowModalInfo = (show: boolean) => {
    this.showModalInfo = show;
  };

  setScannerResult = (data: string) => {
    this.scannerResult = data ?? "";
  };

  setLoading = (enable: boolean) => {
    runInAction(() => {
      this.loading = enable;
    });
  };

  setMessage = (message?: any) => {
    runInAction(() => {
      this.message = null;
      setTimeout(()=>{
        this.message = message?.response?.data?.message || (message || "").toString();
      },100)
    });
  };

  sync = async ()=>{
    try {
      this.rootStore.appStore.setLoading(true)
      this.stateList = await AppApi.stateList();
      try{
        this.aspectRatioList = await AppApi.aspectRatioList();
      }catch (e) {
        this.aspectRatioList = []
      }

    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.setLoading(false)
    }

  }
}
