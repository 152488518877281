import  { ScreenInfo, ScreenApi } from "@services/screenApi";
import { RootStore } from "@store/rootStore";
import { makeAutoObservable, toJS } from "mobx";
import AppApi from "@services/appApi";

export interface IScreenStore {
  get(id: number): ScreenInfo | undefined;
  list(siteId?: string): Promise<ScreenInfo[]>;
}

const NetworkTypes = ["Lobby", "Elevator", "Multi Family Home", "Golf"];

export class ScreenStore implements IScreenStore {
  public screens: ScreenInfo[] = [];
  public currentScreen: ScreenInfo | undefined | null = undefined;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    makeAutoObservable(this);
  }

  get = (id: number): ScreenInfo | undefined => {
    return this.screens.find(el=>+el.serverid === +id)
  };
  
  list = async (): Promise<ScreenInfo[]> => {
    return this.screens;
  };

  setCurrent = async (id: number): Promise<void> => {
    this.currentScreen = this.get(id);
  };

  setScreens = async (screens: ScreenInfo[]): Promise<void> => {
    this.screens = screens
  };
  
  clear = ()=>{
    this.screens = [];
    this.currentScreen = null;
  }

  networkTypes = () => {
    return NetworkTypes;
  };
}
