import { MenuItem, Popover, Popper, styled, Typography } from "@mui/material";
import { PopoverProps } from "@mui/material/Popover/Popover";
import * as React from "react";
import { constants, getVh, getVw } from "@constants/constants";
import { PropsWithChildren, useEffect, useState } from "react";


const MenuItemCustom = styled(MenuItem)(({ theme }) => ({
  "&.MuiMenuItem-root": {
    color: theme.palette.primary.main,
    fontSize: getVw(21),
    minHeight: "10px",
    padding: "10px",
    fontFamily: constants.fontFamily,
    textTransform: "capitalize",
    margin: 0,
    [theme.breakpoints.up('desktop')]: {
      fontSize: 14,
    },
  },
  "&.Mui-selected": {
    // backgroundColor: "rgba(0,0,0,0.4)",
  },
  "&.Mui-disabled": {
    opacity: "1",
  },
}));


const PopperCustom = styled(Popover)(({ theme }) => ({
  ".MuiPaper-root": {
    border: `none`,
    borderRadius: "2px",
    borderTop: "none",
    marginTop: getVh(0),
    margin: "0 0",
    backgroundColor: "transparent",
    backgroundImage: "-webkit-linear-gradient( 90deg, rgba(0,0,0,0.8) 0%, rgba(0,0,0,0.6) 100%)",
    boxShadow: "0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33)",
  },
}));

interface PopoverSelectProps extends PropsWithChildren<any>{
  anchorRef: React.MutableRefObject<any>
  open: boolean
  onClose: ()=>void,
  itemSelect: (item: any)=>void,
  items: any[]
}

export const PopoverSelect = (props: PopoverSelectProps)=>{
  const [state, setState] = useState({width: "100%", left: "0px !important"})
  useEffect(()=>{
    const current = props.anchorRef.current;
    setState({width:`${current.clientWidth}px`, left: `${current.offsetLeft}px !important` })
  },[props.anchorRef?.current?.clientWidth])
  return (
    <PopperCustom
      open={props.open}
      anchorEl={props.anchorRef.current}
      onClose={props.onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      PaperProps={{
        style: { width: state.width, left: state.left },
      }}
    >
      {props.items?.map((el: any) => (
        <MenuItemCustom key={el.id} value={el} onClick={()=>props.itemSelect(el)}>
          {el.email}
        </MenuItemCustom>
      ))}
    </PopperCustom>
  )
}