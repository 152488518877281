
export const constants = {
  desktopWidth: 1224,
  bg: "#FFFFFF",
  bg2: "#40A7FF00",
  primary: "#ffffff",
  secondary: "#009bdb",
  third: "#7B7B7B",
  success: "#2BAC18",
  isDesktop : false,
  backgroundImage: "-webkit-linear-gradient( 90deg, rgba(0,0,0,0.6) 0%, rgba(0,0,0,0.2) 100%)",
  backgroundShadow: "0px 4px 9.4px 0.6px rgba(0, 0, 0, 0.33)",

  fontFamily: "Metropolis",
};

export const getVw = (target: number) => {
  if(constants.isDesktop){
    target*=0.80;
  }
  return `${target}px`;
};

export const getVh = (target: number) => {
  if(constants.isDesktop){
    target*=0.80;
  }
  return `${target}px`;
};
