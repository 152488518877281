import { AuthDto } from "@models/AuthDto";
import { Auth } from "aws-amplify";
import Amplify from "@utils/amplify";

export default class AuthApi {
  public static async signIn(authDto: AuthDto) {
    return await Auth.signIn(authDto.email, authDto.password);
  }

  public static async signOut() {
    return await Auth.signOut()
  }

  public static async session() {
    try {
      return await Auth.currentSession()
    }catch (e) {
      return null
    }
  }

  public static async currentUserInfo() {
    const user = await Auth.currentAuthenticatedUser()
    const credentials = await Auth.currentUserCredentials()
    return {...user, ...credentials}
  }

  public static async search(query?: string){
    const res = await Amplify.get('portalAPI', '/searchnetsuite', {
      searchstring: query || ""
    })
    if(res?.error?.length){
      throw res.error.join(",")
    }
    const res2 = await Amplify.get('AdminQueries', '/listUsers', {
      filter: `email ^= "${query}"`,
    }, 
      {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
      })
    
    return {...res?.data, ...res2};
  }
}
