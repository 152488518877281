import { ContactStore } from "@store/contactStore";
import { InstallerStore } from "@store/installerStore";
import { ScreenStore } from "@store/screenStore";
import { SiteStore } from "@store/siteStore";
import { createRouterState, RouterStore } from "mobx-state-router";

import { routes } from "../routes/routes";
import AppStore from "./appStore";
import AuthStore from "./authStore";

const notFound = createRouterState("notFound");

export class RootStore {
  routerStore = new RouterStore(routes, notFound, {
    rootStore: this
  });
  appStore = new AppStore(this);
  authStore = new AuthStore(this);
  siteStore = new SiteStore(this);
  screenStore = new ScreenStore(this);
  contactStore = new ContactStore(this);
  installerStore = new InstallerStore(this);
}
