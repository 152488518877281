import { IsNotEmpty, IsString } from "class-validator";

export class ScreenDto {
  @IsString()
  @IsNotEmpty()
  screenname!: string;

  // @IsString()
  // @IsNotEmpty()
  // networktype!: string;
  //
  // @IsString()
  // @IsNotEmpty()
  // channelid!: string;
  //
  // @IsString()
  // @IsNotEmpty()
  // macaddress!: string;
  //
  // @IsString()
  // @IsNotEmpty()
  // part!: string;
}
