import { RootStore } from "@store/rootStore";
import { IsNotEmpty, IsString, validate, validateSync } from "class-validator";
import { makeAutoObservable, runInAction } from "mobx";
import SiteApi from "@services/siteApi";
import { ScreenInfo, ScreenApi } from "@services/screenApi";

export interface IInstallerStore {
  nextStep(): number;
  prevStep(): number;
  goToStep(n: number): number;
  setInstaller(installer: InstallerInfo): InstallerInfo;
}

export class InstallerInfo {
  activation: string;
  images: any[] = [];
  screenId!: number;
  floor!: string;
  room!: string;
  closestEntrance!: string;
  closestPointOfInterest!: string;
  installerName!: string;
  aspectRatio: number;

  public constructor(init?: Partial<InstallerInfo>) {
    Object.assign(this, init);
  }
}

const MAX_STEP = 8;
const STEPS = ["Display ID", "Photo 1", "Photo 2", "Photo 3", "Photo 4", "info", "summary", "finish"];

export class InstallerStore implements IInstallerStore {
  public installer: InstallerInfo = new InstallerInfo();
  public stepInstall = 0;
  public steps = STEPS;
  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    this.installer.activation = "";
    makeAutoObservable(this);
  }

  nextStep = (): number => {
    if (this.stepInstall < MAX_STEP) this.stepInstall++;
    return this.stepInstall;
  };

  prevStep = (): number => {
    if (this.stepInstall > 0) this.stepInstall--;
    return this.stepInstall;
  };

  goToStep = (n: number): number => {
    if (n <= MAX_STEP && n >= 0) this.stepInstall = n;
    return this.stepInstall;
  };

  install = async () : Promise<boolean> =>{
    const screen = new ScreenInfo()
    screen.serverid = this.rootStore.screenStore.currentScreen?.serverid || 0;
    screen.aspectratio = this.installer.aspectRatio;
    screen.activationId = this.installer.activation;
    screen.photos = this.installer.images?.map(
      el=>{const copy = {...el};copy.content = el.content.split("base64,")[1]; return copy
      }) || [];
    const locationinfo = [];
    locationinfo.push(this.installer.floor || "");
    locationinfo.push(this.installer.room || "");
    locationinfo.push(this.installer.closestEntrance || "");
    locationinfo.push(this.installer.closestPointOfInterest || "");
    locationinfo.push(this.installer.installerName || "");
    screen.locationinfo = locationinfo.map(el=>el).join(", ")
    try {
      this.rootStore.appStore.loading = true;
      await ScreenApi.install(screen)
      return true;
    }catch (e: any) {
      this.rootStore.appStore.setMessage(e)
      return false;
    }finally {
      this.rootStore.appStore.loading = false;
    }
  }

  setInstaller = (installer: InstallerInfo): InstallerInfo => {
    runInAction(() => {
      this.installer = { ...installer };
    });
    return this.installer;
  };

  clear = ()=>{
    this.installer = new InstallerInfo()
  }
}
