import { Header } from "@components/Header/Header";
import { Menu } from "@components/Menu/Menu";
import { Stepper } from "@components/UI/Stepper";
import { TextFieldSearch } from "@components/UI/TextFieldSearch";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import React, { PropsWithChildren, ReactNode } from "react";

import styles from "./PageLayoutWithStepper.module.scss";

interface PageLayoutProps extends PropsWithChildren<any> {
  headerTitle?: string;
  footer?: ReactNode;
}
export const PageLayoutWithStepper = observer((props: PageLayoutProps) => {
  const {
    installerStore: { stepInstall, steps, goToStep },
    screenStore: { currentScreen },
    siteStore: { currentSite },
    routerStore,
  } = useRootStore();

  const clickStep = (index: number) => {
    goToStep(index);
    switch (index) {
      case 0:
        routerStore.goTo("activation", { params: { id: currentSite?.id, screenId: currentScreen?.serverid } });
        break;
      case 1:
      case 2:
      case 3:
      case 4:
        routerStore.goTo("uploadImage", {
          params: { id: currentSite?.id, screenId: currentScreen?.serverid, step: index },
        });
        break;
      case 5:
        routerStore.goTo("information", {
          params: { id: currentSite?.id, screenId: currentScreen?.serverid },
        });
        break;
      case 6:
        routerStore.goTo("installSummaryInstaller", {
          params: { id: currentSite?.id, screenId: currentScreen?.serverid },
        });
        break;
    }
  };
  return (
    <div className={styles.page}>
      <Header />
      <Menu />
      <div className={styles.content}>{props?.children}</div>
      <div className={styles.stepper}>
        <Stepper steps={steps.slice(0, -1)} activeStep={stepInstall} onClickStep={clickStep} />
      </div>
      <div className={styles.footer}>{props?.footer}</div>
    </div>
  );
});
