import { ScreenInfo } from "@services/screenApi";
import Amplify from "@utils/amplify";
import { daDK } from "@mui/material/locale";

export class SiteInfo {
  id!: number;
  sitename!: string;
  address!: string;
  market!: string;
  displaycount!: number;
  buildingclass!: number;
  installer!: string;
  installPercent: number;
  screens: ScreenInfo[]

  public constructor(init?: Partial<SiteInfo>) {
    Object.assign(this, init);
  }
}


export default class SiteApi {
  public static async installerAssign(id: number, installerMail: string): Promise<boolean> {
    const res = await Amplify.put('portalAPI', '/installerassign', {
      networkvenueid: id,
      installeremail: installerMail
    })
    if(res?.error?.length){
      throw res.error
    }
    return true;
  }
  
  public static async get(id: number): Promise<SiteInfo | undefined> {
    const res = await Amplify.get('portalAPI', '/sitedisplaydata', {
      networkvenueid: id
    })
    if(res?.error?.length){
      throw res.error
    }
    const site = res?.data?.site as SiteInfo;
    site.id = id;
    site.screens = res?.data?.display.map((el:any)=>{el.siteId = id; return el});
    const displaysIsCloudLength = site.screens.filter(el=>el.iscloud).length;
    site.installPercent =  displaysIsCloudLength > 0 ? (displaysIsCloudLength * 100) / site.screens.length : 0;
    return site;
  }

  public static async listByInstaller(email: string, stateId: number): Promise<SiteInfo[]> {

    const res = await Amplify.get('portalAPI', '/sitelistinstaller', {
      installeremail: email,
      state: stateId
    })

    if(res?.error?.length){
      throw res.error.join(",")
    }
    const sites: any[] = [];
    for(const elem of res.data) {
      const site = new SiteInfo();
      site.id = elem?.siteId;
      site.address = elem?.siteaddress;
      site.screens = elem?.displays?.map((el: any) => {
        el.siteId = site.id;
        el.serverid = el.displayid
        return el
      });
      const displaysIsCloudLength = site.screens.filter(el=>el.iscloud).length;
      site.installPercent =  displaysIsCloudLength > 0 ? (displaysIsCloudLength * 100) / site.screens.length : 0;
      sites.push(site)
    }
    return sites as SiteInfo[];
  }

  public static async listByCoordinator(buildingclassId: number, stateId: number): Promise<SiteInfo[]> {

    const res = await Amplify.get('portalAPI', '/sitelistcoordinatorinstall', {
      buildingclass: buildingclassId+"",
      state: stateId
    })

    if(res?.error?.length){
      throw res.error.join(",")
    }
    const sites: any[] = [];
    for(const elem of res.data) {
      const site = new SiteInfo();
      site.id = elem?.siteId;
      site.address = elem?.siteaddress;
      site.screens = elem?.displays?.map((el: any) => {
        el.siteId = site.id;
        el.serverid = el.displayid
        return el
      });
      const displaysIsCloudLength = site.screens.filter(el=>el.iscloud).length;
      site.installPercent =  displaysIsCloudLength > 0 ? (displaysIsCloudLength * 100) / site.screens.length : 0;
      sites.push(site)
    }
    return sites as SiteInfo[];
  }
}