import IconLock from "@assets/icons/icon_lock.svg";
import { PageLayout } from "@components/PageLayout/PageLayout";
import { Button } from "@components/UI/Button";
import { ButtonFill } from "@components/UI/ButtonFill";
import { TextFieldOutline } from "@components/UI/TextFieldOutline";
import { useRootStore } from "@context/rootStoreContext";
import { classValidatorResolver } from "@hookform/resolvers/class-validator/dist/class-validator";
import { AuthDto } from "@models/AuthDto";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { useEffect, useRef } from "react";
import { renderToString } from "react-dom/server";
import { Controller, useForm } from "react-hook-form";

import styles from "./sign.module.scss";

const resolver = classValidatorResolver(AuthDto);

// administrator1 : administrator1@captivate.com : Administrator#1
// administrator2 : administrator2@captivate.com : Administrator#2

// installer1 : p : Installer#1
// installer2 : installer2@captivate.com : Installer#2

export const Sign = observer(() => {
  const {
    authStore: { signIn },
    routerStore,
  } = useRootStore();
  const ref = useRef<HTMLButtonElement>(null)
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AuthDto>({ resolver });

  useEffect(() => {
    const listener = async (event: any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
       event.preventDefault()
        ref?.current?.click()
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return (
    <PageLayout>
      <div className={styles.page}>
        <div className={styles.content}>
          <h1>Sign In</h1>
          <form className={styles.form}>
            <Controller
              name="email"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <TextFieldOutline
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  inputRef={ref}
                  icon={IconLock}
                  errorHelper={errors.email?.message}
                  placeholder={"Email"}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              render={({ field: { onChange, onBlur, value, name, ref } }) => (
                <TextFieldOutline
                  inputProps={{
                    form: {
                      autocomplete: 'off',
                    },
                  }}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  name={name}
                  inputRef={ref}
                  errorHelper={errors.password?.message}
                  icon={IconLock}
                  placeholder={"Password"}
                  type={"password"}
                />
              )}
            />
          </form>
          <Button ref={ref} className={styles.submit} onClick={handleSubmit(signIn)}>submit</Button>
        </div>
      </div>
    </PageLayout>
  );
});
