import { AuthDto } from "@models/AuthDto";
import AuthApi from "@services/authApi";
import { RootStore } from "@store/rootStore";
import { action, extendObservable, makeAutoObservable, makeObservable, observable, runInAction } from "mobx";
import { createRouterState, RouterState } from "mobx-state-router";
import { ContactDto } from "@models/ContactDto";

export interface IAuthStore {
  signIn(user: any): void;
  signUp(user: any): void;
  logout(navigation: any): void;
}

const defaultState = createRouterState("home");

export default class AuthStore implements IAuthStore {
  public status = "";
  public guid = "";
  public email = "";
  public roles: string[] = [];
  public me: ContactDto;

  private rootStore: RootStore;
  signInRedirect: RouterState = defaultState;

  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
    this.checkToken()
  }

  checkToken = async ()=>{
    try{
      const user = await this.getCurrentUserInfo();
      if(!user)
        return null
      this.roles = user.signInUserSession.accessToken.payload["cognito:groups"];
      this.email = user?.attributes?.email;
      this.me = {
        email:user?.attributes?.email,
        phone:user?.attributes?.phone_number,
        permission : this.roles?.[0] || "",
        id: user?.attributes?.sub,
        name: user?.attributes?.name,
        locale: user?.attributes?.locale || "",
      } as ContactDto
      return true
    } catch (e:any) {
      this.rootStore.appStore.setMessage(e.toString())
      return false
    }finally {
      this.rootStore.appStore.setLoading(false);
    }
  }

  setSignInRedirect = (routerState: RouterState) => {
    this.signInRedirect = routerState;
  };

  signIn = async (authDto: AuthDto) => {
    try {
      this.rootStore.appStore.setLoading(true);
      const user = await AuthApi.signIn(authDto);
      await this.checkToken()
      await this.rootStore.routerStore.goTo("home");
      return user
    } catch (e:any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.setLoading(false);
    }

  };

  signUp = async (user: any) => {
    try {
      this.rootStore.appStore.setLoading(true);
    } catch (e:any) {
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.setLoading(false);
    }

  };

  restore = async (email: string) => {
    try {
      this.rootStore.appStore.setLoading(true);
    } catch (e:any) {
      this.rootStore.appStore.setMessage(e)
    } finally {
      this.rootStore.appStore.setLoading(false);
    }
  }

  getCurrentUserInfo = async () => {
    try {
      this.rootStore.appStore.setLoading(true);
      return await AuthApi.currentUserInfo()
    } catch (e:any) {
      this.rootStore.appStore.setMessage(e)
    } finally {
      this.rootStore.appStore.setLoading(false);
    }
  }

  getCurrentSession = async () => {
    try {
      this.rootStore.appStore.setLoading(true);
      return await AuthApi.session()
    } catch (e:any) {
      this.rootStore.appStore.setMessage(e)
    } finally {
      this.rootStore.appStore.setLoading(false);
    }
  }

  logout = async () => {
    runInAction(() => {
      this.status = "";
      this.guid = "";
      this.email = "";
      this.me = new ContactDto();
    });
    await AuthApi.signOut()
  };

  isAdmin = () => {
    return (this.roles || []).includes("Administrator");
  };

  isCoordinator = () => {
    return (this.roles || []).includes("Coordinator");
  };

  isInstaller = () => {
    return (this.roles || []).includes("Installer");
  };


  searchItem = async (query?: string) => {
    try {
      this.rootStore.appStore.setLoading(true);
      const result = await AuthApi.search(query);
      if(!result?.Users?.length && !result?.display?.length && !result?.site?.length ){
        this.rootStore.appStore.setMessage('No results were found')
      }
      return result;
    }catch (e:any){
      this.rootStore.appStore.setMessage(e)
    }finally {
      this.rootStore.appStore.setLoading(false);
    }
  };
}
