import { PageLayoutWithStepper } from "@components/PageLayoutWithStepper/PageLayoutWithStepper";
import { Button } from "@components/UI/Button";
import { useRootStore } from "@context/rootStoreContext";
import { observer } from "mobx-react-lite";
import * as React from "react";

import styles from "./installFinish.module.scss";
import { toJS } from "mobx";

export const InstallFinish = observer(() => {
  const {
    installerStore: { installer },
    screenStore: { currentScreen },
    routerStore,
  } = useRootStore();

  const continueInstall = async () => {
    await routerStore.goTo("search");
  };
  return (
    <PageLayoutWithStepper
      footer={
        <Button onClick={() => continueInstall()}>
          finish
        </Button>
      }
    >
      <div className={styles.content}>
        <h1>{currentScreen?.displayname}</h1>
        <div className={styles.middle}>
          <h2>Install Complete</h2>
          <h2>Thank you!</h2>
        </div>
      </div>
    </PageLayoutWithStepper>
  );
});
