import { IsNotEmpty, IsString } from "class-validator";

export class ScreenInformationDto {
  @IsString()
  @IsNotEmpty()
  floor!: string;

  @IsString()
  @IsNotEmpty()
  room!: string;

  @IsString()
  @IsNotEmpty()
  closestEntrance!: string;

  @IsString()
  @IsNotEmpty()
  closestPointOfInterest!: string;

  @IsString()
  @IsNotEmpty()
  installerName!: string;
}
