import LocationIcon from "@assets/icons/icon_location.svg";
import ScreenIcon from "@assets/icons/icon_screen.svg";
import { PageLayout } from "@components/PageLayout/PageLayout";
import { Button } from "@components/UI/Button";
import { ButtonFill } from "@components/UI/ButtonFill";
import { TextFieldSearch } from "@components/UI/TextFieldSearch";
import { TextFieldSelect } from "@components/UI/TextFieldSelect";
import { useRootStore } from "@context/rootStoreContext";
import { classValidatorResolver } from "@hookform/resolvers/class-validator";
import { SearchInstallerDto } from "@models/SearchInstallerDto";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import { Controller, useForm } from "react-hook-form";

import styles from "./searchInstaller.module.scss";
import { toJS } from "mobx";

const resolver = classValidatorResolver(SearchInstallerDto);

export const SearchInstaller = observer(() => {
  const { siteStore: {sites, currentSite}, screenStore, routerStore, appStore, authStore } = useRootStore();
  const { siteStore } = useRootStore();
  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm<SearchInstallerDto>({ resolver });

  const search = async (data: SearchInstallerDto) => {
    const site = currentSite
    await screenStore.setScreens(site?.screens || [])
    const screen = site?.screens?.find((el: any) => el.displayid === data.displayid);
    if (screen) await routerStore.goTo("activation", { params: { id: site?.id, screenId: screen.serverid } });
  };

  const memoizedCallback = useCallback(
    async (stateId: number | undefined) => {
     siteStore.clear();
      setValue("screen", "")
      setValue("site", "")

      if(stateId) {
        if (authStore.isCoordinator())
          await siteStore.listCoordinator(stateId);
        else
          await siteStore.listInstaller(stateId);
      }
    },
    [screenStore.screens]
  );

  const selectAddress = async (id: string) => {
    if(!id)
      return
    const state = appStore.stateList.find((el: any)=>el.id===id);
    await memoizedCallback(state?.id);
  };
  
  const selectSite = async (id: string) => {
    if(!id)
      return
    const site = (await siteStore.list()).find((el: any) => el.id === id);
    if(site) {
        await siteStore.setCurrentByState(site.id)
        setValue("screen", "")
    }
  };

  return (
    <PageLayout footer={<Button onClick={handleSubmit(search)}>continue</Button>}>
      <div className={styles.content}>
        <h1>Install</h1>
        <form className={styles.form}>
          <Controller
            name="city"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => {
              return <TextFieldSelect
                onChange={(el, node: any) => {
                  selectAddress(node?.props?.['data-id'] || null);
                  onChange(el, node);
                }}
                onBlur={onBlur}
                value={value}
                iconStart={LocationIcon}
                iconStartStyle={{ transform: "scale(1.2)" }}
                name={name}
                inputRef={ref}
                items={appStore.stateList}
                errorHelper={errors.city?.message}
                placeholder={"State/Province"}
              />
            }}
          />
          <Controller
            name="site"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref } }) => (
              <TextFieldSelect
                onChange={(el, node: any) => {
                  onChange(el, node);
                  selectSite(node?.props?.['data-id'] || null);
                }}
                onBlur={onBlur}
                value={value}
                iconStart={LocationIcon}
                iconStartStyle={{ transform: "scale(1.2)" }}
                name={name}
                inputRef={ref}
                items={sites.map((el:any)=>{el.name = el.address; return el})}
                errorHelper={errors.site?.message}
                placeholder={"Address"}
              />
            )}
          />
          <Controller
            name="screen"
            control={control}
            defaultValue=""
            render={({ field: { onChange, onBlur, value, name, ref}, formState}) => (
              <TextFieldSelect
                onChange={(el , node: any)=>{
                    register('displayid')
                    setValue('displayid', node?.props?.['data-id'])
                    onChange(el, node);
                }}
                onBlur={onBlur}
                value={value}
                name={name}
                iconStart={ScreenIcon}
                iconStartStyle={{ transform: "scale(1.7)" }}
                inputRef={ref}
                items={currentSite?.screens.filter(el=>!el.iscloud).map((el:any)=>{el.id = el.displayid; el.name = el.displayname; return el}) || []}
                errorHelper={errors.screen?.message}
                placeholder={"Display"}
              />
            )}
          />
        </form>
      </div>
    </PageLayout>
  );
});
